import { Button, Divider, Form, Input, message, Radio } from "antd";
import "./trade.css";
import React from "react";
import {
  ACC_REQUIRED,
  ERROR,
  NAME_REQUIRED,
  PASSWORD_REQUIRED,
  SERVER_REQUIRED,
  SUCCESS,
} from "../../constants/messages";
import { connect } from "react-redux";
import { postDashboard } from "../../actions/dashboardActions";
import { useHistory } from "react-router-dom";
const CreateAcc = (props) => {


  const nav = useHistory();
  const handelnavigate = () => {
    nav.push("/trading_account");
  };

  const onFinish = (values) => {
    const data = { ...values };
    const accessToken = localStorage.getItem("token");
    props.postDashboard({
      data: { ...data },
      accessToken,
      callback: (status, info) => {
        if (status === ERROR) {
          message.error(info)
        }
        if (status === SUCCESS) {
          handelnavigate()
        }
      },
    });
  };

  const onFinishFailed = (errorInfo) => {
    console.log("Failed:", errorInfo);
  };

  const handleChange = () => {
    // if (hasErrored) {
    //   setHasErrored(false);
    // }
  };
  return (
    <div className="create_trade_main">
      <div className="top_trade">
        <h3>Add New Trading Account Details</h3>
      </div>
      <Divider />
      <div className="create_form">
        <Form
          name="basic"
          initialValues={{
            remember: true,
          }}
          onFinish={onFinish}
          onFinishFailed={onFinishFailed}
        >
          <div className=" form_create">
            <div className="f_label">MT5 Account Number</div>
            <Form.Item
              className="full-width"
              name="login"
              rules={[
                {
                  required: true,
                  message: ACC_REQUIRED,
                },
              ]}
            >
              <Input
                onChange={handleChange}
                className="full-width inputBox-style height-50 b-5"
                placeholder="Enter accoount number"
              />
            </Form.Item>
          </div>
          <div className=" form_create">
            <div className="f_label">MT5 Account password</div>
            <Form.Item
              name="password"
              rules={[
                {
                  required: true,
                  message: PASSWORD_REQUIRED,
                },
              ]}
            >
              <Input.Password
                onChange={handleChange}
                className="b-5 inputBox-style height-50"
                placeholder="Enter your password"
              />
            </Form.Item>
          </div>
          <div className=" form_create">
            <div className="f_label">Broker server</div>
            <Form.Item
              className="full-width"
              name="trading_account_server"
              rules={[
                {
                  required: true,
                  message: SERVER_REQUIRED,
                },
              ]}
            >
              <Input
                onChange={handleChange}
                className="full-width inputBox-style height-50 b-5"
                placeholder="Enter server name"
              />
            </Form.Item>
          </div>
          <div className=" form_create">
            <div className="f_label">Friendly name</div>
            <Form.Item
              className="full-width"
              name="friendly_name"
              rules={[
                {
                  required: true,
                  message: NAME_REQUIRED,
                },
              ]}
            >
              <Input
                onChange={handleChange}
                className="full-width inputBox-style height-50 b-5"
                placeholder="Enter your name"
              />
            </Form.Item>
          </div>
          <div className="form_create">
            <div className="f_label">Account Type</div>
            <Form.Item
              name="acc_type"
              rules={[
                {
                  required: true,
                  message: "Please select an account type!",
                },
              ]}
            >
              <Radio.Group>
                <Radio value="Live">Live</Radio>
                <Radio value="Demo">Demo</Radio>
              </Radio.Group>
            </Form.Item>
          </div>
          <div className=" form_create">
            <div className="f_label"></div>
            <Form.Item>
              <Button
                type="primary"
                className="height-50  full-width form_btn"
                htmlType="submit"
                style={{ marginTop: "15px" }}
              >
                SIGN IN
              </Button>
            </Form.Item>
          </div>
        </Form>
      </div>
    </div>
  );
};



const mapDispatchToProps = {
  postDashboard: postDashboard,
};
const mapStateToProps = ({
  dashboardReducer: { fetchingDashboard },
}) => ({
  fetchingDashboard
});

export default connect(mapStateToProps, mapDispatchToProps)(CreateAcc);