// lightTheme.js
import * as Blockly from 'blockly';

const lightTheme = Blockly.Theme.defineTheme('lightTheme', {
  'base': Blockly.Themes.Classic,
  // 'blockStyles': {
  //   "colour_blocks": {
  //     "colourPrimary": "#f0c674",
  //     "colourSecondary": "#f5e5c0",
  //     "colourTertiary": "#d3a156"
  //   },
  //   "list_blocks": {
  //     "colourPrimary": "#b5a5d4",
  //     "colourSecondary": "#dfd7f5",
  //     "colourTertiary": "#8e7db8"
  //   },
  //   "Custom_Code": {
  //     "colourPrimary": "#b5a5d4",
  //   },
  //   'logic_blocks': {
  //     'colourPrimary': '#4a38a0',
  //     'colourSecondary': '#3a2880',
  //     'colourTertiary': '#2a1870',
  //   },
  //   "loop_blocks": {
  //     "colourPrimary": "#6aa85b",
  //     "colourSecondary": "#c0e0bd",
  //     "colourTertiary": "#4d8a49"
  //   },
  //   "math_blocks": {
  //     "colourPrimary": "#5b67a5",
  //     "colourSecondary": "#c0c5f0",
  //     "colourTertiary": "#494d84"
  //   },
  //   "procedure_blocks": {
  //     "colourPrimary": "#a55ba5",
  //     "colourSecondary": "#f0c0e0",
  //     "colourTertiary": "#84497a"
  //   },
  //   "text_blocks": {
  //     "colourPrimary": "#5ba58c",
  //     "colourSecondary": "#c0e0d5",
  //     "colourTertiary": "#498470"
  //   },
  //   "variable_blocks": {
  //     "colourPrimary": "#a55b99",
  //     "colourSecondary": "#e0c0d0",
  //     "colourTertiary": "#84497a"
  //   },
  //   "variable_dynamic_blocks": {
  //     "colourPrimary": "#a55b99",
  //     "colourSecondary": "#e0c0d0",
  //     "colourTertiary": "#84497a"
  //   },
  //   "hat_blocks": {
  //     "colourPrimary": "#a55b5b",
  //     "colourSecondary": "#e0c0c0",
  //     "colourTertiary": "#844949",
  //     "hat": "cap"
  //   }
  // },
  // 'categoryStyles': {
  //   "colour_category": {
  //     "colour": "#f0c674"
  //   },
  //   "list_category": {
  //     "colour": "#b5a5d4"
  //   },
  //   "logic_category": {
  //     "colour": "#fff"
  //   },
  //   "loop_category": {
  //     "colour": "#6aa85b"
  //   },
  //   "math_category": {
  //     "colour": "#5b67a5"
  //   },
  //   "procedure_category": {
  //     "colour": "#a55ba5"
  //   },
  //   "text_category": {
  //     "colour": "#5ba58c"
  //   },
  //   "variable_category": {
  //     "colour": "#a55b99"
  //   },
  //   "variable_dynamic_category": {
  //     "colour": "#a55b99"
  //   }
  // },
  'componentStyles': {
  //  'workspaceBackgroundColour': '#e6e8ec',
    // 'toolboxBackgroundColour': '#F9C10E',
    // 'toolboxForegroundColour': '#fff',
    // 'flyoutBa0ckgroundColour': '#252526',
    // 'flyoutForegroundColour': '#ccc',
  //   'flyoutOpacity': 1,
  //   'scrollbarColour': '#797979',
  //   'insertionMarkerColour': '#000',
  //   'insertionMarkerOpacity': 0.3,
  //   'markerColour': '#000',
  //   'cursorColour': '#333'
  },
  'fontStyle': {
    'family': 'var(--font-family-head-)',
    'weight': 'normal',
    'size': 10
  },
  'startHats': false
});

export default lightTheme;
