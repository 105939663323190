


export const actionLoginTypes = {
    LOGGING_OUT: "LOGGING_OUT",
    LOG_OUT: "LOG_OUT",
    LOG_OUT_USER: "LOG_OUT_USER",
    GET_USER: "GET_USER",
    USER_RECIEVED: "USER_RECIEVED",
    USER_RECIEVED_LOGIN: "USER_RECIEVED_LOGIN",
    USER_ERROR: "USER_ERROR",
    POST_USER: "POST_USER",
    SET_USER_FETCHING: "SET_USER_FETCHING",
    SET_API_FETCHING: "SET_API_FETCHING",
    GET_SECURITY_QUE: "GET_SECURITY_QUE",
    FORGOT_PASSWORD: "FORGOT_PASSWORD",
    USER_SETTINGS_RECIEVED: "USER_SETTINGS_RECIEVED",
    QUESTIONS_ERROR: "QUESTIONS_ERROR",
    QUESTIONS_RECIEVED: "QUESTIONS_RECIEVED",
    GET_USER_SETTINGS: "GET_USER_SETTINGS",
    POST_USER_SETTINGS: "POST_USER_SETTINGS",
    USER_PAYMENT_RECEIVED: "USER_PAYMENT_RECEIVED",
    CHANGE_EMAIL: "CHANGE_EMAIL",
    EMAIL_UPDATED: "EMAIL_UPDATED",
    RESEND_CODE: "RESEND_CODE",
    VERIFY_CODE: "VERIFY_CODE",
    VERIFY_COUPON_CODE: "VERIFY_COUPON_CODE",
    VERIFY_USER: "VERIFY_USER",
    UPDATE_VERIFY_COUPON_CODE: "UPDATE_VERIFY_COUPON_CODE",
    REFRESH_TOKEN_SUCCESS: "REFRESH_TOKEN_SUCCESS",
    REFRESH_TOKEN_ERROR: "REFRESH_TOKEN_ERROR",
    TOKEN_RECIEVED: "TOKEN_RECIEVED",
    TOKEN_EMPTY: "TOKEN_EMPTY",
};

export const actionQuestionTypes = {
    QUESTIONS: "QUESTIONS",
    FETCHED_QUESTIONS: "FETCHED_QUESTIONS",
    QUESTIONS_ERROR: "QUESTIONS_ERROR",
    POST_QUESTIONS: "POST_QUESTIONS",
    SUBMITTED_QUESTIONS: "SUBMITTED_QUESTIONS",
    RESPONSES: "RESPONSES",
    FETCHED_RESPONSES: "FETCHED_RESPONSES",
    RESPONSES_ERROR: "RESPONSES_ERROR",
};

export const actionPaymentTypes = {
    GET_PLANS: "GET_PLANS",
    PAYMENT_FETCHING: "PAYMENT_FETCHING",
    PLANS_RECIEVED: "PLANS_RECIEVED",
    POST_STRIPE_PAYMENT: "POST_STRIPE_PAYMENT",
    POST_STRIPE_ROBOT_PAYMENT: "POST_STRIPE_ROBOT_PAYMENT",
    PAYMENT_ERROR: "PAYMENT_ERROR",
    POST_PAYPAL_PAYMENT: "POST_PAYPAL_PAYMENT",
    PAYMENT_RECEIVED: "PAYMENT_RECEIVED",
    CANCEL_SUBSCRIPTION: "CANCEL_SUBSCRIPTION",
    SUBSCRIPTION_CANCELLED: "SUBSCRIPTION_CANCELLED",
    UPDATE_PAYPAL_SUBSCRIPTION: "UPDATE_PAYPAL_SUBSCRIPTION",
    POST_PAYPAL_ROBOT_PAYMENT: "POST_PAYPAL_ROBOT_PAYMENT",
};

export const actionWorkspaceTypes = {
    QUESTIONS: "QUESTIONS",
    DELETE_WORKSPACE: "DELETE_WORKSPACE",
    WORKSPACE_ALL_RECIEVED: "WORKSPACE_ALL_RECIEVED",
    FETCHED_WORKSPACE: "FETCHED_WORKSPACE",
    SET_WORKSPACE_FETCHING: "SET_WORKSPACE_FETCHING",
    SET_WORKSPACE_FILE_FETCHING: "SET_WORKSPACE_FILE_FETCHING",
    SET_WORKSPACE_FILE_RECIEVED: "SET_WORKSPACE_FILE_RECIEVED",
    SET_FETCHINGS: "SET_FETCHINGS",
    SET_FETCHING: "SET_FETCHING",
    WORKSPACE_ERROR: "WORKSPACE_ERROR",
    POST_WORKSPACE: "POST_WORKSPACE",
    POST_DASHBOARD: "POST_DASHBOARD",
    AI_WORKSPACE: "AI_WORKSPACE",
    GET_WORKSPACE: "GET_WORKSPACE",
    GET_ALL_WORKSPACE: "GET_ALL_WORKSPACE",
    WORKSPACE_RECIEVED: "WORKSPACE_RESET",
    WORKSPACE_RESET: "WORKSPACE_RECIEVED",
    SUBMITTED_WORKSPACE: "SUBMITTED_WORKSPACE",
    SAVE_FILE: "SAVE_FILE",
    SET_DASHBOARD_FILE_RECIEVED:'SET_DASHBOARD_FILE_RECIEVED',
    SET_DASHBOARD_FILE_FETCHING:'SET_DASHBOARD_FILE_FETCHING',
    DASHBOARD_ERROR: "DASHBOARD_ERROR",
    ACCOUNT_LIST:'ACCOUNT_LIST',
    ACCOUNT_DELETE:'ACCOUNT_DELETE',
    ACCOUNT_PASS:'ACCOUNT_PASS',
    UPDATE_ACCOUNT_DATA:'UPDATE_ACCOUNT_DATA',
    ROBOT_OPTIONS_DROPDOWN:'ROBOT_OPTIONS_DROPDOWN',
    ROBOT_RUN_LIST:'ROBOT_RUN_LIST',
    ROBOT_LIST_START_NOW:'ROBOT_LIST_START_NOW',
    ROBOT_LIST_STOP_NOW:'ROBOT_LIST_STOP_NOW',
    RUN_BACKTEST:'RUN_BACKTEST',
    STOP_BACKTEST:'STOP_BACKTEST',
    UPDATE_BACKTEST:'UPDATE_BACKTEST',
    DELETE_BACKTEST:'DELETE_BACKTEST',
    LIST_BACKTEST:'LIST_BACKTEST',
};
export const actionRobotTypes = {
    ROBOT_ALL_RECIEVED: "ROBOT_ALL_RECIEVED",
    FETCHED_ROBOT: "FETCHED_ROBOT",
    SET_ALL_ROBOT_FETCHING: "SET_ALL_ROBOT_FETCHING",
    ROBOT_ERROR: "ROBOT_ERROR",
    GET_ROBOT: "GET_ROBOT",
    GET_ALL_ROBOTSTORE: "GET_ALL_ROBOTSTORE",
    ROBOT_RECIEVED: "ROBOT_RECIEVED",
    COPY_ROBOT: "COPY_ROBOT",
    COPY_ROBOT_RECIEVED: "COPY_ROBOT_RECIEVED",
};
export const actionRobotRentTypes = {
    DELETE_ROBOT_RENT: "DELETE_ROBOT_RENT",
    ROBOT_RENT_ALL_RECIEVED: "ROBOT_RENT_ALL_RECIEVED",
    FETCHED_ROBOT_RENT: "FETCHED_ROBOT_RENT",
    SET_ALL_ROBOT_RENT_FETCHING: "SET_ALL_ROBOT_RENT_FETCHING",
    ROBOT_RENT_ERROR: "ROBOT_RENT_ERROR",
    POST_ROBOT_RENT: "POST_ROBOT_RENT",
    GET_ROBOT_RENT: "GET_ROBOT_RENT",
    GET_ALL_ROBOT_RENT: "GET_ALL_ROBOT_RENT",
    ROBOT_RENT_RECIEVED: "ROBOT_RENT_RECIEVED",
};
export const actionMySubRobotTypes = {
    MY_SUB_ROBOT_ALL_RECIEVED: "MY_SUB_ROBOT_ALL_RECIEVED",
    FETCHED_MY_SUB_ROBOT: "FETCHED_MY_SUB_ROBOT",
    SET_ALL_MY_SUB_ROBOT_FETCHING: "SET_ALL_MY_SUB_ROBOT_FETCHING",
    MY_SUB_ROBOT_ERROR: "MY_SUB_ROBOT_ERROR",
    GET_MY_SUB_ROBOT: "GET_MY_SUB_ROBOT",
    GET_ALL_MY_SUB_ROBOT: "GET_ALL_MY_SUB_ROBOT",
    MY_SUB_ROBOT_RECIEVED: "MY_SUB_ROBOT_RECIEVED",
    CANCEL_MY_ROBOT_SUBSCRIPTION: "CANCEL_MY_ROBOT_SUBSCRIPTION",
    MY_ROBOT_SUBSCRIPTION_CANCELLED: "MY_ROBOT_SUBSCRIPTION_CANCELLED",
    MY_SUB_ROBOT_EDIT_BROKER_ACCOUNT: "MY_SUB_ROBOT_EDIT_BROKER_ACCOUNT",
    GET_ROBOT_RATING: "GET_ROBOT_RATING",
    RATING_RECEVIED: "RATING_RECEVIED",
    POST_ROBOT_RATING: "POST_ROBOT_RATING",
    DOWNLOAD_FILE: "DOWNLOAD_FILE",
};
