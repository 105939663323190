import React, { useState, useEffect } from "react";
import { Pagination, Rate, Typography, Button } from "antd";
import { ImageBase } from "../../constants/APIConstant";

import DefaultImg from "../../assets/default.png";
import { ROBOT_SUBSCRIBE, PER_MONTH, DOLLAR } from "../../constants/messages";
import { useHistory } from "react-router-dom/cjs/react-router-dom.min";
import CustomPagination from "../CustomPagination/CustomPagination";

const CardList = ({ featureRobot, allRobot, activTab, freeRobot ,setcpage }) => {
  const [data, setData] = useState([]);
  const [displayData, setDisplayData] = useState([]);
  const { Text } = Typography;
  const history = useHistory();

  useEffect(() => {
    setData([]);
    if (activTab === 1) {
      setData(featureRobot);
    } else if (activTab === 2) {
      setData(allRobot);
    } else {
      setData(freeRobot);
    }
  }, [featureRobot, allRobot, freeRobot]);


  const ImageComponent = ({ src, alt, fallbackSrc }) => {
    const handleImageError = (event) => {
      event.target.src = fallbackSrc;
    };

    return <img src={src} alt={alt} onError={handleImageError} />;
  };
  const namehandle = (name) => {
    if (name.length > 25) {
      const first = name?.substring(0, 20) + "..." + name?.slice(-4);
      const second = first?.charAt(0).toUpperCase() + first?.slice(1);
      return second;
    } else {
      return name?.charAt(0)?.toUpperCase() + name?.slice(1);
    }
  };

  return (
    <>
      <div className="card_main store">
        {displayData &&
          displayData.map((card) => (
            <div class="card-custom">
              <ImageComponent
                src={`${
                  card.image_1 ? ImageBase.dev + card.image_1 : DefaultImg
                }`}
                alt="Example Image"
                fallbackSrc={DefaultImg}
              />
              <div className="data-content">
                <div className="c_name">
                  <h3>{namehandle(card?.name)}</h3>
                  <div className="rating">
                    <Rate
                      disabled
                      value={card?.avg_rating}
                      style={{ color: "#EDA830",fontSize:'18px' }}
                    />
                  </div>
                </div>
                <div className="c_bottom">
                  {card.rent_amount ? (
                    <Text className="font price_card">
                      
                      <sup>{DOLLAR} </sup>
                      {card.rent_amount}
                      <span>{PER_MONTH}</span>
                    </Text>
                  ) : (
                    <Text className="font price_card">Free</Text>
                  )}
                  <div className="buttons">
                    <Button
                      type="primary"
                      className="button primary width-full b-5 btns"
                      onClick={() => history.push(`/robot_store/${card?.id}`)}
                    >
                      {ROBOT_SUBSCRIBE}
                    </Button>
                  </div>
                </div>
              </div>
            </div>
          ))}
      </div>
      <CustomPagination setcpage={setcpage} record={data} setDisplayData={setDisplayData} />
    </>
  );
};

export default CardList;
