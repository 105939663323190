import React, { useEffect, useState } from 'react';

import "./robotStore.css"
import { Row, Typography } from 'antd';
import { ROBOT_FEATURES, ROBOT_STORE, BIG, MEDIUM, ROBOT_FREE } from "../../constants/messages";
import { getAllRobotStoreList } from "../../actions/robotStoreActions";
import { connect } from "react-redux";
import { Tabs } from 'antd';
import MyCard from './Card';
import Loader from '../Loader/Loader';

const RobotStoreTab = (props) => {
    const [allRobot, setAllRobot] = useState([]);
    const [featureRobot, setFeatureRobot] = useState([]);
    const [freeRobot, setFreeRobot] = useState([]);
    const [cpage, setcpage] = useState(1);

    const [activeTab, setActiveTab] = useState('1');

    const { TabPane } = Tabs;
    const handleTabChange = (key) => {
        setActiveTab(key);
    };

    useEffect(() => {
        window.scrollTo(0, 0)
        const { accessToken } = props;
        props.getAllRobotStoreList({
            accessToken: accessToken,
            callback: (resp, data) => {
                if (resp === 'success') {
                    setFeatureRobot(data?.featured_robot)
                    setAllRobot(data?.all_robot)
                    setFreeRobot(data?.free_robot)
                }
            },
        })
    }, []);

    useEffect(() => {
        const { allRobotList } = props;
        if (props.allRobotList) {
            // setAllRobot(allRobotList.all_robot);
            // setFeatureRobot(allRobotList.featured_robot);
            // setFreeRobot(allRobotList.free_robot);
        }
    }, [props.allRobotList])

    const tabBarStyle = {
        color: 'var(--secondary-color)',
    };
  
    return (
        <div className="robotstore fill-container-height ">
            <Tabs centered activeKey={activeTab}
                tabBarStyle={tabBarStyle}
                onChange={handleTabChange}>
                <TabPane tab={ROBOT_FEATURES} key="1">
                    {
                        featureRobot ?
                            <MyCard featureRobot={featureRobot} activTab={1} setcpage={setcpage} />
                            : <Row justify="center" style={{ marginTop: 60 }} ><Loader /> </Row>
                    }

                </TabPane>
                <TabPane tab='ALL Robots' key="2">
                    {
                        allRobot ?
                            <MyCard allRobot={allRobot} activTab={2} setcpage={setcpage} />
                            : <Row justify="center" style={{ marginTop: 60 }} ><Loader /> </Row>
                    }
                </TabPane>
                <TabPane tab={ROBOT_FREE} key="3">
                    {
                        freeRobot ?
                            <MyCard freeRobot={freeRobot} activTab={3} setcpage={setcpage} />
                            : <Row justify="center" style={{ marginTop: 60 }} ><Loader /> </Row>
                    }
                </TabPane>
            </Tabs>
        </div>
    )
}
const mapDispatchToProps = {
    getAllRobotStoreList: getAllRobotStoreList,
};
const mapStateToProps = ({
    robotStoreReducer: {
        allRobotList,fetchingAllRobot
    },
    userReducer: {
        accessToken,
        payment,
    },
}) => ({
    accessToken,
    allRobotList,
    payment,
    fetchingAllRobot
});


export default connect(mapStateToProps, mapDispatchToProps)(RobotStoreTab);