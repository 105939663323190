import { actionWorkspaceTypes } from "../constants/actionTypes";

export const postDashboard = ({accessToken, data, id, callback}) => {
  return {
    type: actionWorkspaceTypes.POST_DASHBOARD,
    data: data,
    accessToken: accessToken,
    callback: callback
  }
}
export const postAccountDetails = ({accessToken, data, callback}) => {
  return {
    type: actionWorkspaceTypes.UPDATE_ACCOUNT_DATA,
    data: data,
    accessToken: accessToken,
    callback: callback
  }
}
export const deleteAcc = ({accessToken, data, callback}) => {
  return {
    type: actionWorkspaceTypes.ACCOUNT_DELETE,
    data: data,
    accessToken: accessToken,
    callback: callback
  }
}

export const getAccList= ({ accessToken, callback}) => {
    return {
      type: actionWorkspaceTypes.ACCOUNT_LIST,
      accessToken: accessToken,
      callback: callback
    };
  };
export const getAccPass= ({ data,accessToken, callback}) => {
    return {
      type: actionWorkspaceTypes.ACCOUNT_PASS,
      data:data,
      accessToken: accessToken,
      callback: callback
    };
  };
export const getRobotOptionList= ({ accessToken, callback}) => {
  return {
    type: actionWorkspaceTypes.ROBOT_OPTIONS_DROPDOWN,
    accessToken: accessToken,
    callback: callback
  };
};
export const getRobotRunList= ({ accessToken, data,callback}) => {
  return {
    type: actionWorkspaceTypes.ROBOT_RUN_LIST,
    accessToken: accessToken,
    callback: callback,
    data:data
  };
};
export const getRobotList= ({ accessToken, callback,data}) => {
  return {
    type: actionWorkspaceTypes.ROBOT_LIST_START_NOW,
    data: data,
    accessToken: accessToken,
    callback: callback
  };
};
export const stopRobot= ({ accessToken, callback,data}) => {
  return {
    type: actionWorkspaceTypes.ROBOT_LIST_STOP_NOW,
    data: data,
    accessToken: accessToken,
    callback: callback
  };
};
export const runDashboard= ({ accessToken, callback,data}) => {
  return {
    type: actionWorkspaceTypes.RUN_BACKTEST,
    data: data,
    accessToken: accessToken,
    callback: callback
  };
};
export const stopDashboard= ({ accessToken, callback,data}) => {
  return {
    type: actionWorkspaceTypes.STOP_BACKTEST,
    data: data,
    accessToken: accessToken,
    callback: callback
  };
};
export const updateDashboard= ({ accessToken, callback,data}) => {
  return {
    type: actionWorkspaceTypes.UPDATE_BACKTEST,
    data: data,
    accessToken: accessToken,
    callback: callback
  };
};
export const deleteDashboard= ({ accessToken, callback,data}) => {
  return {
    type: actionWorkspaceTypes.DELETE_BACKTEST,
    data: data,
    accessToken: accessToken,
    callback: callback
  };
};
export const listBacktest= ({ accessToken, callback,data}) => {
  return {
    type: actionWorkspaceTypes.LIST_BACKTEST,
    accessToken: accessToken,
    callback: callback,
    data:data
  };
};
