import { actionWorkspaceTypes } from "../constants/actionTypes";

const initialData = {
  fetchingDashboard: false,
};

const dashboardReducer = (state = initialData, action) => {
  switch (action.type) {
      case actionWorkspaceTypes.SET_DASHBOARD_FILE_FETCHING:
        return {
          ...state,
          fetchingDashboard: true,
        };
      case actionWorkspaceTypes.SET_DASHBOARD_FILE_RECIEVED:
        return {
          ...state,
          fetchingDashboard: false,
        };
      case actionWorkspaceTypes.DASHBOARD_ERROR:
        return {
          ...state,
          fetchingDashboard: false,
        };
    
    default:
      return state;
  }
};
export default dashboardReducer;
