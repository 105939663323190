import React, { useEffect, useState } from "react";
import "./trade.css";
import { Button, Divider, message, Modal } from "antd";
import { useHistory } from "react-router-dom";
import { connect } from "react-redux";
import {
  deleteAcc,
  getAccList,
  getAccPass,
  postAccountDetails,
} from "../../actions/dashboardActions";
import { ERROR, SUCCESS, THEME_COLOR } from "../../constants/messages";

const TradingAcc = (props) => {
  const [lists, setlists] = useState([]);
  const [visible, setvisible] = useState(false);
  const [passShow, setpassShow] = useState(false);
  const [editData, seteditData] = useState("");
  const [selectedOption, setSelectedOption] = useState("");
  const [password, setpassword] = useState("");
  const [loader, setloader] = useState(false);

  const handleOptionChange = (event) => {
    setSelectedOption(event.target.value);
  };

  const nav = useHistory();
  const handelnavigate = () => {
    nav.push("/create_acc");
  };

  const getPass = (pass) => {
    props.getAccPass({
      data: pass,
      accessToken: localStorage.getItem("token"),
      callback: (status, info) => {
        setloader(false);
        if (status === ERROR) {
          message.error(info || "error");
          setpassword("");
          setpassShow(true);
        }
        if (status === SUCCESS) {
          setpassword(info);
          setpassShow(!passShow);
        }
      },
    });
  };
  const getListsData = () => {
    props.getAccList({
      accessToken: localStorage.getItem("token"),
      callback: (status, info) => {
        if (status === ERROR) {
          message.error(info || "error");
          setlists([]);
        }
        if (status === SUCCESS) {
          setlists(info);
        }
      },
    });
  };
  useEffect(() => {
    getListsData();
  }, []);

  const handleShowPassword = (pass) => {
    if (passShow) {
      setpassShow(!passShow);
    } else {
      setloader(true);
      getPass(pass);
    }
  };

  const handleShowModal = (el) => {
    setvisible(true);
    seteditData(el);
    setSelectedOption(el?.trading_account_type);
  };
  const handleUpdate = () => {
    const data = {
      trading_account_number: editData?.trading_account_number,
      account_type: selectedOption,
    };
    const accessToken = localStorage.getItem("token");

    props.postAccountDetails({
      data: { ...data },
      accessToken,
      callback: (status, info) => {
        if (status === ERROR) {
          message.error(info);
        }
        if (status === SUCCESS) {
          getListsData();
          handlecancel();
        }
      },
    });
  };

  const handlecancel = () => {
    setvisible(false);
    seteditData("");
    setSelectedOption("");
  };
  const handleDelete = (trading_account_number) => {
    const data = { trading_account_number: trading_account_number };
    const accessToken = localStorage.getItem("token");

    props.deleteAcc({
      data: { ...data },
      accessToken,
      callback: (status, info) => {
        if (status === ERROR) {
          message.error(info);
        }
        if (status === SUCCESS) {
          getListsData();
          handlecancel();
        }
      },
    });
  };

  return (
    <div className="trade_main">
      <div className="top_trade">
        <h3>Trading Account</h3>
        <button onClick={handelnavigate}>
          <img
            style={{ height: "40px" }}
            src={require("../../assets/robotasset/prebtn.png")}
            alt=""
          />
          <p>Add trading account</p>
        </button>
      </div>
      <Divider />
      <div className="bottom_trade">

        {!lists?.length >0 ?
        <div className="rec_nf">
           Records not found.
        </div>
        :lists?.map((el) => {
          return (
            <>
              <div className="trade_card">
                <div className="c_top">
                  <div className="c_head">
                    <span>Trading account</span>
                    {/* <p>{el?.trading_account_number}</p> */}
                  </div>
                  <div className="c_text">
                    <p>{el?.trading_account_type}</p>
                    <img
                      onClick={() => handleShowModal(el)}
                      src={require("../../assets/robotasset/outedit.png")}
                      alt=""
                    />
                  </div>
                </div>
                <div className="c_detail">
                  <span>Friendly Name</span>
                  <p>{el?.trading_account_name}</p>
                </div>
                <div className="c_detail">
                  <span>Login</span>
                  <p>{el.trading_account_number}</p>
                </div>
                <div className="c_detail">
                  <span>Server</span>
                  <p>{el?.trading_account_server}</p>
                </div>
                <div className="c_detail">
                  <span>Deployed</span>
                  <p>{el?.trading_account_status ? "Active" : "Inactive"}</p>
                </div>
                <div className="c_detail">
                  <span>Password</span>
                  <p
                    style={{
                      display: "flex",
                      alignItems: "center",
                    }}
                  >
                    {loader ? (
                      <span>Loading..</span>
                    ) : (
                      <>
                        <span>{passShow ? password : "******"}</span>
                        <img
                          src={require("../../assets/robotasset/Show.png")}
                          onClick={() =>
                            handleShowPassword(el?.trading_account_password)
                          }
                          alt=""
                        />
                      </>
                    )}
                  </p>
                </div>
                <div className="btns_trade">
                  <div
                    className="del_trade"
                    onClick={() => handleDelete(el.trading_account_number)}
                  >
                    <img
                      src={require("../../assets/robotasset/btndel.png")}
                      alt=""
                    />
                    <p>Delete</p>
                  </div>
                  <div className="go_trade">
                    <p>go to account</p>
                    <img
                      src={require("../../assets/robotasset/btnarrow.png")}
                      alt=""
                    />
                  </div>
                </div>
              </div>
            </>
          );
        })}
      </div>
      <Modal
        title="Update Account type"
        visible={visible}
        closable={false}
        bodyStyle={{ color: THEME_COLOR }}
        footer={[
          <Button
            key="submit"
            className="b-5"
            type="primary"
            onClick={handleUpdate}
          >
            Update
          </Button>,
          <Button
            key="submit"
            className="b-5"
            type="danger"
            onClick={handlecancel}
          >
            Cancel
          </Button>,
        ]}
      >
        <h3>
          Trading Account :-{" "}
          <span style={{ fontWeight: "bold" }}>
            {editData?.trading_account_number}
          </span>
        </h3>

        <div className="option_select">
          <h3>Select an option :- </h3>
          <div style={{ marginLeft: "5px" }}>
            <label style={{ marginRight: "10px" }}>
              <input
                type="radio"
                value="Live"
                checked={selectedOption === "Live"}
                onChange={handleOptionChange}
                style={{ marginRight: "5px" }}
              />
              Live
            </label>

            <label style={{ marginRight: "5px" }}>
              <input
                type="radio"
                value="Demo"
                checked={selectedOption === "Demo"}
                onChange={handleOptionChange}
                style={{ marginRight: "5px" }}
              />
              Demo
            </label>
          </div>
        </div>
      </Modal>
    </div>
  );
};

const mapDispatchToProps = {
  getAccList: getAccList,
  postAccountDetails: postAccountDetails,
  deleteAcc: deleteAcc,
  getAccPass: getAccPass,
};
const mapStateToProps = ({ dashboardReducer: { fetchingDashboard } }) => ({
  fetchingDashboard,
});

export default connect(mapStateToProps, mapDispatchToProps)(TradingAcc);
