import { combineReducers } from "redux";
import paymentReducer from './paymentReducer'
import questionReducer from "./questionReducer";
import userReducer from "./userReducer";
import workspaceReducer from "./workspaceReducer";
import robotStoreReducer from "./robotStoreReducer";
import robotRentReducer from  "./robotRentReducer";
import mySubRobotReducer from "./mySubRobotReducer";
import dashboardReducer from "./dashboardReducer";

const appReducer = combineReducers({
  userReducer,
  questionReducer,
  workspaceReducer,
  paymentReducer,
  robotStoreReducer,
  robotRentReducer,
  mySubRobotReducer,
  dashboardReducer
});

const rootReducer = (state, action) => {
  return appReducer(state, action);
};

export default rootReducer;
