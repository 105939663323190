/**
 * @fileoverview robot rent window for robot subscription
 */

import React, { useEffect, useState } from "react";
import Carousel from "../commonComponent/carousel/carousel";
import CardContainer from "../commonComponent/carousel/CardContainer";
import "./robotRent.css";
import { Row, Typography } from "antd";
import {
  BIG,
  MY_ROBOT,
  RENT_ROBOT,
  MEDIUM,
  SUCCESS,
} from "../../constants/messages";
import { getAllRobotRentList } from "../../actions/robotRentActions";
import { connect } from "react-redux";
import { Grid } from "antd";
import Loader from "../Loader/Loader";
import RentCards from "./RentCard";
import RentCard from "../commonComponent/carousel/rentCard";
function RobotRent(props) {
  const { Title } = Typography;

  const [allRobotRent, setAllRobotRent] = useState([]);
  const [loading, setLoading] = useState(false);
  const [apiloading, setapiLoading] = useState(false);

  const { useBreakpoint } = Grid;
  const { lg, xl, xxl } = useBreakpoint();
  const isBelow1200px = !xl;
  const isBelow992px = !lg;

  useEffect(() => {
    window.scrollTo(0, 0);
    const { accessToken, userData } = props;
    setapiLoading(true);
    props.getAllRobotRentList({
      accessToken: accessToken,
      id: userData.user_id,
      callback: (resp, data) => {
        if (resp === SUCCESS) {
          // setAllRobotRent(data)
        }
        setapiLoading(false);
      },
    });
  }, []);

  useEffect(() => {
    const { allRentRobotList } = props;

    if (allRentRobotList) {
      const createdList = allRentRobotList.all_robot?.filter(
        (e) => e.copied_robot === false
      );

      setAllRobotRent(createdList);
      setLoading(true);
    }
  }, [props.allRentRobotList]);

  return (
    <>
      <div className="robotrent_main">
        <div className="robotrents fill-container-height text-center">
          {apiloading ? (
            <Row justify="center" style={{ marginTop: 60 }}>
              <Loader />{" "}
            </Row>
          ) : allRobotRent && allRobotRent?.length > 0 ? (
            loading && (
              <div className="card">
                <RentCards featureRobot={allRobotRent} />
              </div>
            )
          ) : (
            <h3 className=" m4">You have no robots</h3>
          )}
        </div>
      </div>
    </>
  );
}

const mapDispatchToProps = {
  getAllRobotRentList: getAllRobotRentList,
};
const mapStateToProps = ({
  robotRentReducer: { allRentRobotData, allRentRobotList },
  userReducer: { accessToken, payment, userData },
}) => ({
  allRentRobotData,
  accessToken,
  allRentRobotList,
  userData,
  payment,
});

export default connect(mapStateToProps, mapDispatchToProps)(RobotRent);
