// ForecastTable.js
import React, { useEffect, useMemo, useState } from "react";
import { useTable, usePagination, useSortBy } from "react-table";
import {
  Select,
  Input,
  Button,
  message,
  Modal,
  Popconfirm,
  Progress,
} from "antd";
import { useHistory } from "react-router-dom";
import Expand from "../../assets/expand.png";
import {
  deleteDashboard,
  getAccList,
  getRobotList,
  getRobotOptionList,
  getRobotRunList,
  listBacktest,
  runDashboard,
  stopDashboard,
  stopRobot,
} from "../../actions/dashboardActions";
import {
  ERROR,
  MOBILE_CONTENT,
  MOBILE_HEADER,
  SUCCESS,
} from "../../constants/messages";
import { connect } from "react-redux";
import Swap from "../../assets/robotasset/Swap.png";
import Ace from "../../assets/robotasset/asc.png";
import Dec from "../../assets/robotasset/dec.png";

function BacktestResult(props) {
  const [records, setrecords] = useState([]);

  const [symbol, setSymbol] = useState("");
  const [timeframe, setTimeframe] = useState("");
  const [filterAction, setfilterAction] = useState("");
  const [searchString, setSearchString] = useState("");

  const [pagecount, setpagecount] = useState(1);
  const [totalrecord, settotalrecord] = useState(0);
  const [searchbtn, setsearchbtn] = useState(true);

  const [refresh, setrefresh] = useState(false);
  const [mobileWarning, setMobileWarning] = useState(false);
  const navigate = useHistory();
  const [sortBy, setSortBy] = useState("");
  const [sortOrder, setSortOrder] = useState({
    period: "asc",
    symbol: "asc",
    robot_name: "asc",
  });

  const handleSort = (columnId) => {
    setSortBy(columnId);
    setSortOrder((prevOrder) => {
      const newOrder = prevOrder[columnId] === "asc" ? "desc" : "asc";
      return { ...prevOrder, [columnId]: newOrder };
    });
  };
  const data = useMemo(() => records, [records]);

  const handleChangeselect = (value) => {
    setSymbol(value);
  };
  const searchText = (e, dateString) => {
    setSearchString(e.target.value);
  };

  const handleChangetimeframe = (value) => {
    setTimeframe(value);
  };
  const handleChangeAction = (value) => {
    setfilterAction(value);
  };

  // Retest backtest

  const confirm = (e, status, data) => {
    handleToggleStatus(e, status, data);
  };
  const handleToggleStatus = (rowId, status, data) => {
    const row = records.find((record) => record.id === rowId);

    if (!row) return;
    if (status === "Stop") {
      updateStatusAPI(row.id);
    }
    if (status === "Run") {
      const params = {};
      params.trading_account_number = data?.trading_account_number;
      params.timeframe = data?.timeframe;
      params.symbol = data?.symbol;
      params.backtest_name = data?.backtest_name;
      params.method = data?.method;
      params.currency = data?.currency;
      params.applied_price = data?.applied_price;
      params.deposit = data?.deposit;
      params.from_date = data?.from_date;
      params.to_date = data?.to_date;
      params.leverage = data?.leverage;
      params.shift = data?.shift;
      params.backtest_set = data?.backtest_set;
      params.robot = data?.robot;

      const accessToken = localStorage.getItem("token");
      props.runDashboard({
        data: params,
        accessToken,
        callback: (status, info) => {
          if (status === SUCCESS) {
            message.success("Backtest run Successfuly");
          }
          if (status === ERROR) {
            message.error(info);
          }
        },
      });
    }
  };

  const updateStatusAPI = async (rowId) => {
    const params = {};
    if (rowId) params.hypa_account_robot_id = rowId;
    const accessToken = localStorage.getItem("token");

    console.log(params);

    props.stopDashboard({
      data: params?.hypa_account_robot_id,
      accessToken,
      callback: (status, info) => {
        if (status === ERROR) {
        }
        if (status === SUCCESS) {
          setrefresh(!refresh);
        }
      },
    });
  };

  const cancel = (e) => {};

  // Filter

  useEffect(() => {
    if (symbol !== "" || timeframe !== "" || filterAction !== "" ||searchString!=='') {
      setsearchbtn(false);
    }
  }, [symbol, timeframe, filterAction,searchString]);

  // Backtest List

  const getRunRobotList = (key) => {
    if (key === "search") {
      setpagecount(1);
    }
    const params = {};
    if (pagecount) params.page = pagecount;
    if (sortBy) params.sort_by = sortBy;
    if (sortOrder[sortBy]) params.sort_order = sortOrder[sortBy];
    if (symbol) params.symbol = symbol;
    if (filterAction) params.filterAction = filterAction;
    if (timeframe) params.period = timeframe;
    if (searchString) params.search_query = searchString;
    props.listBacktest({
      accessToken: localStorage.getItem("token"),
      data: params,
      callback: (status, info) => {
        if (status === ERROR) {
          message.error(info || "error");
        }
        if (status === SUCCESS) {
          if (info) {
            setrecords(info?.results);
            settotalrecord(info?.count);
          }
        }
      },
    });
  };

  useEffect(() => {
    getRunRobotList();
  }, [sortBy, sortOrder, pagecount, refresh]);

  const deleteBacktest = async (rowId) => {
    const accessToken = localStorage.getItem("token");

    props.deleteDashboard({
      data: rowId,
      accessToken,
      callback: (status, info) => {
        if (status === ERROR) {
        }
        if (status === SUCCESS) {
          setrefresh(!refresh);
        }
      },
    });
  };

  const handlePdfClick = (data) => {
    // console.log(data);
    
    const queryParameters = new URLSearchParams();
    queryParameters.append("id",data?.id);
    queryParameters.append("period", data?.timeframe);
    queryParameters.append("trading_account_number", data?.trading_account_number);
    navigate.push({
      pathname: "/full_info",
      search: `?${queryParameters.toString()}`,
    });
  };



  const columns = useMemo(
    () => [
      { Header: "Backtest Name", accessor: "backtest_name" },
      {
        Header: () => (
          <div onClick={() => handleSort("symbol", sortBy)}>
            Instruments
            <img
              style={{ height: "15px" }}
              src={
                sortBy === ""
                  ? Swap
                  : sortBy === "symbol"
                  ? sortOrder.symbol === "asc"
                    ? Ace
                    : Dec
                  : Swap
              }
              alt="img"
            />
          </div>
        ),
        accessor: "symbol",
      },

      { Header: "Account Number", accessor: "trading_account_number" },
      { Header: "Start Balance", accessor: "deposit" },
      {
        Header: "End Balance",
        accessor: "end_balance",
        Cell: ({ row }) => (
          <p
            style={{
              cursor: "pointer",
            }}
          >
            {row.original.end_balance ? row.original.end_balance : "-"}
          </p>
        ),
      },
      {
        Header: "Start Date",
        accessor: "from_date",
      },
      {
        Header: "Stop Date",
        accessor: "to_date",
      },
      {
        Header: () => (
          <div onClick={() => handleSort("period", sortBy)}>
            Timeframe
            <img
              style={{ height: "15px" }}
              src={
                sortBy === ""
                  ? Swap
                  : sortBy === "period"
                  ? sortOrder.period === "asc"
                    ? Ace
                    : Dec
                  : Swap
              }
              alt="img"
            />
          </div>
        ),
        accessor: "timeframe",
      },
      {
        Header: "currency pair",
        accessor: "currency",
      },
      {
        Header: "Full Info",
        accessor: "expand",
        Cell: ({ row }) => (
          <img style={{cursor:'pointer'}} src={Expand} onClick={()=>handlePdfClick(row.original)} alt="" />
        ),
      },
      {
        Header: "Progress",
        accessor: "progress",
        Cell: ({ row }) => (
          <Progress
            type="circle"
            width={55}
            strokeColor="var(--primary-color)"
            size="small"
            percent={row.original.progress||0}
          />
        ),
      },
      {
        Header: "Lot Size",
        accessor: "lot_size",
        Cell: ({ row }) => (
          <p
            style={{
              cursor: "pointer",
            }}
          >
            {row.original.lot_size ? row.original.lot_size : "-"}
          </p>
        ),
      },
      {
        Header: "Max Drowdown",
        accessor: "max_drowdown",
        Cell: ({ row }) => (
          <p
            style={{
              cursor: "pointer",
            }}
          >
            {row.original.max_drowdown ? row.original.max_drowdown : "-"}
          </p>
        ),
      },
      {
        Header: "Profit",
        accessor: "profit",
        Cell: ({ row }) => (
          <p
            style={{
              cursor: "pointer",
            }}
          >
            {row.original.profit ? row.original.profit : "-"}
          </p>
        ),
      },
      {
        Header: "Profit %",
        accessor: "profit_percentage",
        Cell: ({ row }) => (
          <p
            style={{
              cursor: "pointer",
            }}
          >
            {row.original.profit_percentage
              ? row.original.profit_percentage + "%"
              : "-"}
          </p>
        ),
      },
      
      {
        Header: "Status",
        accessor: "status",
        Cell: ({ row }) => (
          <span
            style={{
              color:row.original.status === "CANCELLED" ||row.original.status === "FAILED" ?'red':row.original.status === "RUNNING" ||row.original.status === "COMPLETED" ? "green" : "blue",
              fontWeight: "bold",
            }}
          >
            {row.original.status}
          </span>
        ),
      },
      {
        Header: "Action",
        accessor: "robot_status",
        Cell: ({ row }) => (
          <>
            <Popconfirm
              title={`Do you want to ${
                row.original.status === "RUNNING" ? "Stop" : "Run"
              }?`}
              onConfirm={() =>
                confirm(
                  row.original.id,
                  row.original.status === "RUNNING" ? "Stop" : "Run",
                  row.original
                )
              }
              disabled={row.original.status==='RUNNING' || row.original.status==='QUEUE'}
              onCancel={cancel}
              okText="Yes"
              cancelText="No"
            >
              <button
                style={{
                  padding: "5px 10px",
                  backgroundColor:
                    row.original.status === "RUNNING" ? "red" : "green",
                  color: "white",
                  border: "none",
                  borderRadius: "5px",
                  cursor: "pointer",
                }}
              >
                {row.original.status === "RUNNING" ? "Stop" : "Run"}
              </button>
            </Popconfirm>
          </>
        ),
      },
      {
        Header: "Archive",
        accessor: "archive",
        Cell: ({ row }) => (
          <>
            <Popconfirm
              title={`Are you sure ?`}
              onConfirm={() => deleteBacktest(row.original.id)}
              onCancel={cancel}
              okText="Yes"
              cancelText="No"
            >
              <button
              disabled={row.original.status==='RUNNING' || row.original.status==='QUEUE'}
                style={{
                  padding: "5px 10px",
                  backgroundColor: "#003A96",
                  color: "white",
                  border: "none",
                  borderRadius: "5px",
                  cursor: "pointer",
                }}
              >
                Archive
              </button>
            </Popconfirm>
          </>
        ),
      },
    ],
    [records, sortOrder, sortBy]
  );


  const {
    getTableProps,
    getTableBodyProps,
    headerGroups,
    prepareRow,
    page,
    pageOptions,
  } = useTable(
    {
      columns,
      data,
      manualPagination: true,
      pageCount: Math.ceil(totalrecord / 10),
    },
    useSortBy,
    usePagination
  );

  useEffect(() => {
    function updateSize() {
      setMobileWarning(window.innerWidth < 700);
    }
    window.addEventListener("resize", updateSize);
    updateSize();
    return () => {
      window.removeEventListener("resize", updateSize);
    };
  }, []);
  const handleFilterReset = () => {
    setTimeframe("");
    setfilterAction("");
    setSymbol("");
    setsearchbtn(true);
    setpagecount(1);
    setrefresh(!refresh);
    setSortBy("");
    setSearchString("");
  };
  const handleFilterRefresh = () => { 
    setrefresh(!refresh); 
  };

  return (
    <div className="table_pannel backtest_table">
      <div className="" style={{ padding: "30px", background: "#fff" }}>
        <div className="search_pannel" style={{gap:'15px'}}>
        <div className="filter_block">
            <p className="filter-label">Search by</p>
            <Input
               value={searchString}
              className="Sinput"
              placeholder="Search..."
              suffix={
                <img
                  className="searchimg"
                  src={require("../../assets/robotasset/Search.png")}
                  alt="date icon"
                />
              }
              onChange={searchText}
              onKeyDown={(event)=>{
                if(event.key === "Enter"){
                  getRunRobotList("search");
                }

              }}
            />
          </div>
          <div className="filter_block">
            <p className="filter-label">Currency Pair/Assets</p>
            <Select
              showSearch
              optionFilterProp="children"
              filterOption={(input, option) =>
                (option?.label ?? "").includes(input.toUpperCase())
              }
              filterSort={(optionA, optionB) =>
                (optionA?.label ?? "")
                  .toUpperCase()
                  .localeCompare((optionB?.label ?? "").toUpperCase())
              }
              value={symbol === "" ? null : symbol}
              placeholder="Select symbol"
              className="sym_select"
              onChange={handleChangeselect}
              options={[
                { value: "CADJPY", label: "CADJPY" },
                { value: "USDJPY", label: "USDJPY" },
                { value: "AUDUSD", label: "AUDUSD" },
                { value: "EURCAD", label: "EURCAD" },
                { value: "EURCHF", label: "EURCHF" },
                { value: "AUDNZD", label: "AUDNZD" },
                { value: "EURAUD", label: "EURAUD" },
                { value: "EURJPY", label: "EURJPY" },
                { value: "GER40",  label: "GER40" },
                { value: "US500",  label: "US500" },
                { value: "GBPNZD", label: "GBPNZD" },
                { value: "EURUSD", label: "EURUSD" },
                { value: "NZDUSD", label: "NZDUSD" },
                { value: "USDCAD", label: "USDCAD" },
                { value: "AUDCAD", label: "AUDCAD" },
                { value: "UK100",  label:  "UK100"  },
                { value: "XAGUSD", label: "XAGUSD" },
                { value: "EURNZD", label: "EURNZD" },
                { value: "GBPCHF", label: "GBPCHF" },
                { value: "XTIUSD", label: "XTIUSD" },
                { value: "CHFJPY", label: "CHFJPY" },
                { value: "NZDCAD", label: "NZDCAD" },
                { value: "GBPAUD", label: "GBPAUD" },
                { value: "BTCUSD", label: "BTCUSD" },
                { value: "NZDCHF", label: "NZDCHF" },
                { value: "US30",   label: "US30"   },
                { value: "NZDJPY", label: "NZDJPY" },
                { value: "CADCHF", label: "CADCHF" },
                { value: "XAUUSD", label: "XAUUSD" },
                { value: "USDCHF", label: "USDCHF" },
                { value: "EURGBP", label: "EURGBP" },
                { value: "GBPCAD", label: "GBPCAD" },
                { value: "GBPJPY", label: "GBPJPY" },
                { value: "AUDJPY", label: "AUDJPY" },
                { value: "GBPUSD", label: "GBPUSD" },
                { value: "ETHUSD", label: "ETHUSD" },
              ]}
            />
          </div>
          <div className="filter_block">
            <p className="filter-label">Timeframe</p>
            <Select
              value={timeframe === "" ? null : timeframe}
              className="sym_select"
              placeholder="Select timeframe"
              onChange={handleChangetimeframe}
              options={[
                { value: "M30", label: "M30" },
                { value: "M15", label: "M15" },
                { value: "H1", label: "H1" },
                { value: "H4", label: "H4" },
                { value: "D1", label: "D1" },
              ]}
            />
          </div>
          <div className="filter_block">
            <p className="filter-label">Action</p>
            <Select
              value={filterAction === "" ? null : filterAction}
              className="sym_select"
              placeholder="Select action"
              onChange={handleChangeAction}
              options={[
                { value: "COMPLETED", label: "COMPLETED" },
                { value: "QUEUE", label: "QUEUE" },
                { value: "RUNNING", label: "RUNNING" },
                { value: "FAILED", label: "FAILED" },
                { value: "CANCELLED", label: "CANCELLED" },
              ]}
            />
          </div>
          <div className="filter_block btn-grp">
            <Button
              disabled={searchbtn}
              type="primary btns srch_btn"
              onClick={() => getRunRobotList("search")}
            >
              Filter
            </Button>
            {!searchbtn && (
              <Button type="primary btns srch_btn" onClick={handleFilterReset}>
                Reset
              </Button>
            )}
              <Button type="primary btns srch_btn" onClick={handleFilterRefresh}>
                Refresh
              </Button>
          </div>
        </div>
        <div className="filter_pannel">
        {
          !records?.length >0 ?
          <div className="rec_nf">
              <p>Records not found.</p>
          </div>
         : <table
            {...getTableProps()}
            style={{
              width: "100%",
              borderCollapse: "collapse",
              border: "solid 1px #DBDCDE",
            }}
          >
            <thead>
              {headerGroups.map((headerGroup) => (
                <tr {...headerGroup.getHeaderGroupProps()}>
                  {headerGroup.headers.map((column) => (
                    <th
                      {...column.getHeaderProps(column.getSortByToggleProps())}
                      style={{
                        padding: "15px",
                        background: "var(--primary-color)",
                        color: "#fff",
                        fontWeight: "bold",
                        cursor: "pointer",
                        textTransform: "uppercase",
                        textAlign: "center",
                        fontSize: "15px",
                      }}
                    >
                      {column.render("Header")}
                    </th>
                  ))}
                </tr>
              ))}
            </thead>
            <tbody {...getTableBodyProps()}>
              {page.map((row) => {
                prepareRow(row);
                return (
                  <tr {...row.getRowProps()}>
                    {row.cells.map((cell) => (
                      <td
                        {...cell.getCellProps()}
                        style={{
                          padding: "10px 15px",
                          borderBottom: "solid 1px var(--bordercolor)",
                          background: "#fff",
                          textAlign: "center",
                          fontWeight: "700",
                          fontSize: "14px",
                        }}
                      >
                        {cell.render("Cell")}
                      </td>
                    ))}
                  </tr>
                );
              })}
            </tbody>
          </table>
        }

          <div className="pagination">
            <button
              className="pbtn fbtn"
              onClick={() => setpagecount(1)}
              disabled={pagecount <= 0}
            >
              <img
                src={require("../../assets/robotasset/firstarroe.png")}
                alt=""
              />
              first
            </button>{" "}
            <button
              className="pbtn fbtn"
              onClick={() => setpagecount(pagecount > 1 ? pagecount - 1 : 1)}
              disabled={pagecount <= 0}
            >
              <img
                src={require("../../assets/robotasset/backarrow.png")}
                alt=""
              />
              back
            </button>{" "}
            {/* {renderPageNumbers()} */}
            <button
              className="pbtn lbtn"
              onClick={() => setpagecount(pagecount + 1)}
              disabled={pagecount === pageOptions.length}
            >
              next
              <img src={require("../../assets/robotasset/prarr.png")} alt="" />
            </button>{" "}
            <button
              onClick={() => setpagecount(Math.ceil(totalrecord / 10))}
              disabled={pagecount === pageOptions.length}
              className="pbtn lbtn"
            >
              last
              <img src={require("../../assets/robotasset/lastp.png")} alt="" />
            </button>
            <span>
              Page{" "}
              <strong>
                {pagecount} of {pageOptions.length}
              </strong>{" "}
            </span>
          </div>
        </div>
      </div>
      <Modal
        title={MOBILE_HEADER}
        visible={mobileWarning}
        footer={null}
        closable={false}
      >
        <p>{MOBILE_CONTENT}</p>
      </Modal>
    </div>
  );
}

const mapDispatchToProps = {
  listBacktest: listBacktest,
  getRobotOptionList: getRobotOptionList,
  getRobotList: getRobotList,
  runDashboard: runDashboard,
  stopDashboard: stopDashboard,
  deleteDashboard: deleteDashboard,
};
const mapStateToProps = ({ dashboardReducer: { fetchingDashboard } }) => ({
  fetchingDashboard,
});
export default connect(mapStateToProps, mapDispatchToProps)(BacktestResult);
