import React, { useEffect, useState } from "react";
import "./info.css";
import { Divider, Progress } from "antd";
import { useHistory } from "react-router-dom";
import { updateDashboard } from "../../actions/dashboardActions";
import { connect } from "react-redux";
import { ERROR, SUCCESS } from "../../constants/messages";

const FullInfo = (props) => {
  const [expertData, setExpertData] = useState([]);
  const [expertData2, setExpertData2] = useState([]);
  const [filteredData, setFilteredData] = useState({});

  const urlParams = new URLSearchParams(window.location.search);
  const bID = urlParams.get("id");
  const period = urlParams.get("period");
  const bAcc = urlParams.get("trading_account_number");

  const history = useHistory();

  useEffect(() => {
    if (bAcc && bID) {
      handleFileInputChange();
    }
  }, [bAcc, bID]);

  const handleFileInputChange = (event) => {
    const URLS = `${process.env.REACT_APP_AWS_BUCKET}/${bAcc}_${bID}/report.htm`;
    const file = URLS;
    if (!file) return;

    const reader = new FileReader();

    reader.onload = (e) => {
      const htmlContent = e.target.result;
      const parsedData = parseHTML(htmlContent);
      const parsedData2 = parseHTML2(htmlContent);
      setExpertData(parsedData);
      setExpertData2(parsedData2);
    };
    fetch(URLS)
      .then((response) => response.blob())
      .then((blob) => reader.readAsText(blob))
      .catch((error) => console.error("Error fetching file:", error));
  };

  const parseHTML = (htmlContent) => {
    const parser = new DOMParser();
    const doc = parser.parseFromString(htmlContent, "text/html");

    const rows = doc.querySelectorAll("tr");
    let data = [];

    for (let row of rows) {
      const cells = row.querySelectorAll("td");
      if (cells.length >= 2) {
        const key = cells[0]?.textContent.trim().replace(/\s+/g, "");
        const value = cells[1]?.textContent.trim().replace(/\s+/g, "");

        const match = value.match(/Lots=[^\s]+/);
        const result = match ? match[0] : null;
        const obj = result ? { Lots: result.split("=")[1] } : null;

        if (obj) {
          data.push(obj);
        }

        if (key === "OpenTime" && value === "Order") {
          break;
        }
        if (key.includes(":") && key && value) {
          let newKey = key.replace(":", "");
          data.push({ [newKey]: value });
        }
      }
    }

    return data;
  };
  const parseHTML2 = (htmlContent) => {
    const parser = new DOMParser();
    const doc = parser.parseFromString(htmlContent, "text/html");
    const rows = doc.querySelectorAll("tr");
    let data = [];

    let pattern = /:$/;

    let mainData = [];

    for (let row of rows) {
      const cells = row.querySelectorAll("td");
      let _data = [];
      cells.forEach((it, idx) => {
        if (typeof it == undefined) {
          return;
        }

        if (
          it &&
          it !== undefined &&
          it != null &&
          it?.innerText !== undefined &&
          it?.innerText !== "" &&
          it?.innerText?.includes(":") &&
          pattern.test(it?.innerText)
        ) {
          const newKey = it?.innerText.replace(":", "");
          _data.push({ [newKey]: cells[idx + 1].innerText });
        }
      });
      mainData.push(_data);
    }

    data = mainData.flat(Infinity).filter(Boolean);

    return data;
  };

  const keys = [
    "Symbol",
    "Initial Deposit",
    "History Quality",
    "Bars",
    "Total Net Profit",
    "Gross Profit",
    "Gross Loss",
    "Profit Factor",
    "Total Trades",
    "Ticks",
    "Short Trades (won %)",
    "Long Trades (won %)",
    "Largest profit trade",
    "Average profit trade",
    "Maximum consecutive wins ($)",
    "Average consecutive wins",
    "Balance Drawdown Maximal",
    "Equity Drawdown Maximal",
    "Initial Deposit  ",
    "Period",
    "Lots",
    "Currency",
  ];

  useEffect(() => {
    if (expertData && expertData2) {
      const resultarr = expertData.concat(expertData2);
      if (resultarr) {
        const encounteredKeys = new Set();
        const filtered = resultarr.filter((item) => {
          const key = Object.keys(item)[0];
          if (keys.includes(key) && !encounteredKeys.has(key)) {
            encounteredKeys.add(key);
            return true;
          }
          return false;
        });

        const newState = filtered.reduce((acc, item) => {
          const key = Object.keys(item)[0];
          acc[key] = item[key];
          return acc;
        }, {});

        const updatedData = {};
        Object.keys(newState).forEach((key) => {
          const newKey = key
            .replace(/\s+/g, "")
            .replace(/\(won%\)/g, "")
            .replace(/\(\$?\)/g, "");
          updatedData[newKey] = newState[key];
        });

        setFilteredData(updatedData);
      }
    }
  }, [expertData, expertData2]);

  const RemoveSpace = (val) => {
    const rs = val?.replace(/\s+/g, "");
    return rs;
  };
  function getDatesFromBrackets(input) {
    const match = input?.match(
      /\((\d{4}\.\d{2}\.\d{2})\s*-\s*(\d{4}\.\d{2}\.\d{2})\)/
    );

    if (match) {
      return {
        startDate: match[1],
        endDate: match[2],
      };
    }

    return null;
  }
  const dates = getDatesFromBrackets(filteredData?.Period);

  const handlePdfClick = () => {
    const filePath = `${process.env.REACT_APP_AWS_BUCKET}/${bAcc}_${bID}/report.pdf`;
    window.open(filePath, "_blank");
  };

  const updateStatusAPI = async (data) => {
    const params = {};
    if (data?.Lots) params.lot_size = data?.Lots;
    if (bID) params.row_id = bID;
    const accessToken = localStorage.getItem("token");

    console.log(params);

    props.updateDashboard({
      data: params,
      accessToken,
      callback: (status, info) => {
        if (status === ERROR) {
        }
        if (status === SUCCESS) {
        }
      },
    });
  };

  useEffect(() => {
    if (Object.keys(filteredData)?.length > 0) {
      updateStatusAPI(filteredData);
    }
  }, [filteredData]);

  return (
    <div className="full_main">
      <div className="full_top">
        <div className="full_left">
          <div className="full_left_top">
            <div className="full_card">
              <img src={require("../../assets/robotasset/sb.png")} alt="" />
              <div className="fc_right">
                <div className="fc_title">Starting Balance</div>
                <div className="fc_value">
                  {(
                    Number(RemoveSpace(filteredData?.InitialDeposit)) || 0
                  ).toLocaleString("en-US", {
                    style: "currency",
                    currency: "USD",
                  })}
                </div>
              </div>
            </div>
            <div className="full_card">
              <img src={require("../../assets/robotasset/piggi.png")} alt="" />
              <div className="fc_right">
                <div className="fc_title">End Balance</div>
                <div className="fc_value">
                  {" "}
                  {(
                    (Number(RemoveSpace(filteredData?.InitialDeposit)) || 0) +
                      (Number(RemoveSpace(filteredData?.TotalNetProfit)) ||
                        0) || "-"
                  ).toLocaleString("en-US", {
                    style: "currency",
                    currency: "USD",
                  })}
                </div>
              </div>
            </div>
            <div className="full_card">
              <img src={require("../../assets/robotasset/expert.png")} alt="" />
              <div className="fc_right">
                <div className="fc_title">Total Net Profit</div>
                <div className="fc_value">
                  {(
                    Number(RemoveSpace(filteredData?.TotalNetProfit)) ||
                    0 ||
                    "-"
                  ).toLocaleString("en-US", {
                    style: "currency",
                    currency: "USD",
                  })}
                </div>
              </div>
            </div>
            <div className="full_card">
              <img src={require("../../assets/robotasset/pf.png")} alt="" />
              <div className="fc_right">
                <div className="fc_title">Profit Factor</div>
                <div className="fc_value">
                  {Number(RemoveSpace(filteredData?.ProfitFactor)) || 0 || "-"}
                </div>
              </div>
            </div>
          </div>
          <div className="full_left_bottom">
            <img
              height={425}
              src={`${process.env.REACT_APP_AWS_BUCKET}/${bAcc}_${bID}/report.png`}
              alt=""
              onError={(e) => {
                e.target.src = require("../../assets/robotasset/graph.png");
              }}
            />
          </div>
        </div>
        <div className="full_right">
          <div className="full_left_top">
            <div className="full_card">
              <img src={require("../../assets/robotasset/pie.png")} alt="" />
              <div className="fc_right">
                <div className="fc_title">Short positions won</div>
                <div className="fc_value">
                  {filteredData?.ShortTrades || "-"}
                </div>
              </div>
            </div>
            <div className="full_card">
              <img src={require("../../assets/robotasset/pie.png")} alt="" />
              <div className="fc_right">
                <div className="fc_title">Long positions won</div>
                <div className="fc_value">
                  {filteredData?.LongTrades || "-"}
                </div>
              </div>
            </div>
          </div>
          <div className="full_right_bottom">
            <div
              className=""
              style={{ display: "flex", gap: "15px", justifyContent: "center" }}
            >
              <div className="full_bottom_card">
                <img
                  src={require("../../assets/robotasset/Users.png")}
                  alt=""
                />
                <h3>Max Drawdown</h3>
                <h1>
                  {Number(
                    filteredData?.EquityDrawdownMaximal?.split("(")[1]?.slice(
                      0,
                      -2
                    )
                  ) || 0}
                  %
                </h1>
                <Progress
                  strokeColor="#6E39CB"
                  trailColor="#D3C4EF"
                  percent={
                    Number(
                      filteredData?.EquityDrawdownMaximal?.split("(")[1]?.slice(
                        0,
                        -2
                      )
                    ) || 0
                  }
                  showInfo={false}
                />
              </div>
              <div className="full_bottom_card">
                <img
                  src={require("../../assets/robotasset/Clicks.png")}
                  alt=""
                />
                <h3>Total Equity Risk</h3>
                <h1>45.55%</h1>
                <Progress
                  strokeColor="#5DAB36"
                  trailColor="#CEE6C3"
                  percent={70}
                  showInfo={false}
                />
              </div>
            </div>
            <div
              className=""
              style={{
                display: "flex",
                gap: "15px",
                justifyContent: "center",
                marginTop: "25px",
              }}
            >
              <div className="full_bottom_card">
                <img
                  src={require("../../assets/robotasset/Clicks.png")}
                  alt=""
                />
                <h3>Absolute Drawdown</h3>
                <h1>
                  {filteredData?.BalanceDrawdownMaximal?.split("(")[1]?.slice(
                    0,
                    -2
                  ) || 0}
                  %
                </h1>
                <Progress
                  strokeColor="#6E39CB"
                  trailColor="#D3C4EF"
                  percent={filteredData?.BalanceDrawdownMaximal?.split(
                    "("
                  )[1]?.slice(0, -2)}
                  showInfo={false}
                />
              </div>
              <div className="full_bottom_card">
                <img
                  src={require("../../assets/robotasset/Items.png")}
                  alt=""
                />
                <h3>Total Equity Used</h3>
                <h1>
                  {filteredData?.EquityDrawdownMaximal?.split("(")[1]?.slice(
                    0,
                    -2
                  ) || 0}
                  %
                </h1>
                <Progress
                  strokeColor="#5DAB36"
                  trailColor="#CEE6C3"
                  percent={
                    filteredData?.EquityDrawdownMaximal?.split("(")[1]?.slice(
                      0,
                      -2
                    ) || 0
                  }
                  showInfo={false}
                />
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="full_bottom">
        <div className="full_bottom_title">
          <h3>Setting</h3>
          <button onClick={() => handlePdfClick()} className="pdf_download">
            Export Full Report
          </button>
        </div>
        <Divider />
        <div className=" bottom_main">
          <div className="bottom_details">
            <div className="detail1">
              <div className="">
                <h3>{filteredData?.Symbol || "-"}</h3>
                <p>Currency Pair / Asset</p>
              </div>
              <div className="">
                <h3>{period}</h3>
                <p>Timeframe</p>
              </div>
              <div className="">
                <h3>
                  {(
                    Number(filteredData?.Largestprofittrade) || "-"
                  ).toLocaleString("en-US", {
                    style: "currency",
                    currency: "USD",
                  })}
                </h3>
                <p>Largest Profit Trade</p>
              </div>
            </div>
          </div>
          <div className="bottom_details">
            <div className="detail1">
              <div className="">
                <h3>
                  {" "}
                  {(
                    Number(filteredData?.Averageprofittrade) || "-"
                  ).toLocaleString("en-US", {
                    style: "currency",
                    currency: "USD",
                  })}
                </h3>
                <p>Average Profit trade</p>
              </div>
              <div className="">
                <h3>{filteredData?.Maximumconsecutivewins || "-"}</h3>
                <p>Maximum consecutive wins</p>
              </div>
              <div className="">
                <h3>{filteredData?.Lots || 0}</h3>
                <p>Lot size</p>
              </div>
            </div>
          </div>
          <div className="bottom_details">
            <div className="detail1">
              <div className="">
                <h3>{filteredData?.Currency}</h3>
                <p>Account Currency</p>
              </div>
              <div className="">
                <h3 style={{ color: "green" }}>
                  {" "}
                  {(
                    Number(RemoveSpace(filteredData?.GrossProfit)) || "-"
                  ).toLocaleString("en-US", {
                    style: "currency",
                    currency: "USD",
                  })}
                </h3>
                <p>GROSS PROFIT</p>
              </div>
              <div className="">
                <h3 style={{ color: "red" }}>
                  {(
                    Number(RemoveSpace(filteredData?.GrossLoss)) || "-"
                  ).toLocaleString("en-US", {
                    style: "currency",
                    currency: "USD",
                  })}
                </h3>
                <p>GROSS LOSS</p>
              </div>
            </div>
          </div>
          <div className="bottom_details">
            <div className="detail1">
              <div className="">
                <h3>{dates?.startDate + "-" + dates?.endDate}</h3>
                <p>Date</p>
              </div>
              <div className="">
                <h3>Every Tick</h3>
                <p>Method</p>
              </div>
              <div className="">
                <h3>{filteredData?.Bars || "-"}</h3>
                <p>Bars in Test</p>
              </div>
            </div>
          </div>
          <div className="bottom_details">
            <div className="detail1">
              <div className="">
                <h3>{filteredData?.Averageconsecutivewins || "-"}</h3>
                <p>Average consecutive wins</p>
              </div>
              <div className="">
                <h3>{filteredData?.Ticks || "-"}</h3>
                <p>Ticks Modelled</p>
              </div>
              <div className="">
                <h3>{filteredData?.TotalTrades || "-"}</h3>
                <p>Total Trades</p>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

const mapDispatchToProps = {
  updateDashboard: updateDashboard,
};
const mapStateToProps = ({ dashboardReducer: { fetchingDashboard } }) => ({
  fetchingDashboard,
});
export default connect(mapStateToProps, mapDispatchToProps)(FullInfo);
