import React, { useEffect, useState } from "react";
import "./backtest.css";
import {
  Button,
  Col,
  DatePicker,
  Divider,
  Form,
  Input,
  message,
  Row,
  Select,
} from "antd";
import moment from "moment";
import {
  GlobalStates,
  getAllWorkspaceList,
  getWorkspace,
} from "../../actions/workspaceActions";
import { connect } from "react-redux";
import { ERROR, SUCCESS, UNTITLED_ROBOT } from "../../constants/messages";
import { Option } from "antd/lib/mentions";
import {
  InputField,
  SelectField,
} from "../commonComponent/drawers/indicatorDrawer";
import {
  INDICATOR_DATATYPES,
  INDICATOR_DRAWER_STATE,
} from "../../constants/constants.Indicator";
import { DEFAULT_INDICATOR_STATE } from "../../constants/constants";
import { HELP_TEXT } from "../../constants/constants.popover";
import { isEmpty } from "../../utils/utils";
import { COMMON_TEXT } from "../../constants/constants.string";
import DateTimeComponent from "../AiForcast/DateTimeComponent";
import { makeGlobalCode } from "../workspaceWindow/utils";
import { getAccList, runDashboard } from "../../actions/dashboardActions";
import { useHistory } from "react-router-dom";

const Backtest = (props) => {
  const [currentName, setCurrentName] = useState("");
  const [code, setcode] = useState("");
  const [lists, setlists] = useState([]);

  const [tickCode, settickCode] = useState("");
  const [initCode, setinitCode] = useState("");
  const [barCode, setbarCode] = useState("");
  const [editCode, seteditCode] = useState("");

  const [codeBlock, setCodeBlock] = useState([]);
  const [codeBlockError, setCodeBlockError] = useState({});
  const [popOverContent, setPopOverContent] = useState({});
  const [indicatorType, setindicatorType] = useState([]);
  const [finalObj, setfinalObj] = useState("");

  const navigate = useHistory();

  const [formValues, setFormValues] = useState({
    trading_account: "",
    symbol: "",
    timeframe: "",
    backtest_name: "",
    method: "",
    currency: "",
    shift: 0,
    applied_price: "",
    deposit: 1000,
    from_date: null,
    to_date: null,
    leverage: "",
  });
  const symbolList = [
    { value: "CADJPY", label: "CADJPY" },
    { value: "USDJPY", label: "USDJPY" },
    { value: "AUDUSD", label: "AUDUSD" },
    { value: "EURCAD", label: "EURCAD" },
    { value: "EURCHF", label: "EURCHF" },
    { value: "AUDNZD", label: "AUDNZD" },
    { value: "EURAUD", label: "EURAUD" },
    { value: "EURJPY", label: "EURJPY" },
    { value: "GER40", label: "GER40" },
    { value: "US500", label: "US500" },
    { value: "GBPNZD", label: "GBPNZD" },
    { value: "EURUSD", label: "EURUSD" },
    { value: "NZDUSD", label: "NZDUSD" },
    { value: "USDCAD", label: "USDCAD" },
    { value: "AUDCAD", label: "AUDCAD" },
    { value: "UK100", label: "UK100" },
    { value: "XAGUSD", label: "XAGUSD" },
    { value: "EURNZD", label: "EURNZD" },
    { value: "GBPCHF", label: "GBPCHF" },
    { value: "XTIUSD", label: "XTIUSD" },
    { value: "CHFJPY", label: "CHFJPY" },
    { value: "NZDCAD", label: "NZDCAD" },
    { value: "GBPAUD", label: "GBPAUD" },
    { value: "BTCUSD", label: "BTCUSD" },
    { value: "NZDCHF", label: "NZDCHF" },
    { value: "US30", label: "US30" },
    { value: "NZDJPY", label: "NZDJPY" },
    { value: "CADCHF", label: "CADCHF" },
    { value: "XAUUSD", label: "XAUUSD" },
    { value: "USDCHF", label: "USDCHF" },
    { value: "EURGBP", label: "EURGBP" },
    { value: "GBPCAD", label: "GBPCAD" },
    { value: "GBPJPY", label: "GBPJPY" },
    { value: "AUDJPY", label: "AUDJPY" },
    { value: "GBPUSD", label: "GBPUSD" },
    { value: "ETHUSD", label: "ETHUSD" },
  ];
  const ltag = [
    { label: "1:500", value: "1:500" },
    { label: "1:200", value: "1:200" },
    { label: "1:100", value: "1:100" },
    { label: "1:50", value: "1:50" },
    { label: "1:33", value: "1:33" },
    { label: "1:25", value: "1:25" },
    { label: "1:15", value: "1:15" },
    { label: "1:10", value: "1:10" },
    { label: "1:5", value: "1:5" },
    { label: "1:3", value: "1:3" },
    { label: "1:1", value: "1:1" },
  ];

  const [form] = Form.useForm();

  const onFinish = (values) => {
    // Format dates before submission

    if (code?.id) {
      const formattedValues = {
        ...values,
        from_date: values.from_date
          ? moment(values.from_date).format("YYYY-MM-DD")
          : null,
        to_date: values.to_date
          ? moment(values.to_date).format("YYYY-MM-DD")
          : null,
        backtest_set: finalObj,
        robot: code?.id,
      };

      setFormValues(formattedValues);
      const accessToken = props.accessToken;
      props.runDashboard({
        data: formattedValues,
        accessToken,
        callback: (status, info) => {
          if (status === SUCCESS) {
            message.success("Backtest run Successfuly");
            navigate.push("/backtest-result");
          }
          if (status === ERROR) {
            message.error(info);
          }
        },
      });
    } else {
      message.error("Please select your robot");
    }
  };

  const getListsData = () => {
    props.getAccList({
      accessToken: localStorage.getItem("token"),
      callback: (status, info) => {
        if (status === ERROR) {
          message.error(info || "error");
          setlists([]);
        }
        if (status === SUCCESS) {
          setlists(info);
        }
      },
    });
  };
  useEffect(() => {
    getListsData();
  }, []);
  useEffect(() => {
    setCodeBlockError({});
    if (!isEmpty(code)) {
      if (code[indicatorType]) {
        setCodeBlock({ ...code[indicatorType] });
      } else {
        indicatorType !== code["name"]
          ? setCodeBlock({ ...DEFAULT_INDICATOR_STATE[indicatorType] })
          : setCodeBlock({ ...code });
      }
    }
    setPopOverContent({ ...HELP_TEXT[indicatorType] });
  }, [indicatorType, code]);

  useEffect(() => {
    props.getAllWorkspaceList({
      accessToken: localStorage.getItem("token"),
    });
  }, []);

  const handleWorkspaceChange = (key) => {
    setCurrentName(key);
    const { accessToken } = props;
    props.getWorkspace({
      id: key,
      accessToken,
      callback: (res, data) => {
        if (res === "success") {
          setcode(data);
          settickCode(data?.tick_options);
          setinitCode(data?.init_options);
          setbarCode(data?.bar_options);
        }
      },
    });
  };

  useEffect(() => {
    seteditCode(tickCode || initCode || barCode || "");
  }, [tickCode, initCode, barCode]);

  useEffect(() => {
    if (editCode !== "") {
      const filterAdjustableWithNamePrefix = (data) => {
        return Object.keys(data)
          .map((key) => data[key].code)
          .filter((code) => {
            return Object.values(code).some(
              (item) => item?.ADJUSTABLE === true
            );
          });
      };

      const filteredData = filterAdjustableWithNamePrefix(editCode);
      setCodeBlock(filteredData);
    }
  }, [editCode]);

  const handleFieldChange = (
    value,
    fieldName,
    blockIndex,
    isFieldRequired = false
  ) => {
    setCodeBlock((prevCodeBlock) => {
      const updatedCodeBlock = [...prevCodeBlock];
      // Safely update the VALUE for the specific field in the block
      if (
        updatedCodeBlock[blockIndex] &&
        updatedCodeBlock[blockIndex][fieldName]
      ) {
        updatedCodeBlock[blockIndex][fieldName]["VALUE"] = value;
      }
      return updatedCodeBlock;
    });

    setCodeBlockError((prevCodeBlockError) => ({
      ...prevCodeBlockError,
      [fieldName]: {
        status: value || value === 0 || !isFieldRequired ? false : true,
        help:
          value || value === 0 || !isFieldRequired
            ? null
            : COMMON_TEXT.EMPTY_STRING,
      },
    }));
  };

  const handleCheckChange = (isChecked, fieldName, blockIndex) => {
    setCodeBlock((prevCodeBlock) => {
      const updatedCodeBlock = [...prevCodeBlock];
      if (
        updatedCodeBlock[blockIndex] &&
        updatedCodeBlock[blockIndex][fieldName]
      ) {
        updatedCodeBlock[blockIndex][fieldName]["ADJUSTABLE"] = isChecked;
      }
      return updatedCodeBlock;
    });
  };

  const filterAdjustableWithNamePrefix = (data) => {
    return Object.keys(data)
      .map((key) => data[key].code) // Extract 'code' objects
      .filter((code) => {
        // Check if any key inside 'code' has 'ADJUSTABLE' set to true
        return Object.values(code).some((item) => item?.ADJUSTABLE === true);
      })
      .map((code) => {
        // Filter the code object to include only keys with 'ADJUSTABLE: true'
        const filteredCode = Object.keys(code)
          .filter((key) => {
            const value = code[key];
            return value?.ADJUSTABLE === true; // Keep properties where ADJUSTABLE is true
          })
          .reduce((obj, key) => {
            obj[key] = code[key]; // Keep the key-value pair in the new object
            return obj;
          }, {});

        // Also include 'name' and 'prefix' in the result
        if (code.name) {
          filteredCode.name = code.name;
        }
        if (code.prefix) {
          filteredCode.prefix = code.prefix;
        }

        return filteredCode;
      });
  };

  const filteredData = filterAdjustableWithNamePrefix(editCode);
  const fields = filteredData?.map((el) => INDICATOR_DRAWER_STATE[el?.name]);

  const filterArr1 = (arr1, arr2) => {
    return arr1.map((section, index) => {
      return section.filter((field) => {
        const fieldName = field.NAME;
        const correspondingItem = arr2[index];

        if (correspondingItem && correspondingItem[fieldName]) {
          return correspondingItem[fieldName].ADJUSTABLE === true;
        }

        return false;
      });
    });
  };

  const finalList = filterArr1(fields, filteredData);

  const getGcode = async (code) => {
    const g_code = await makeGlobalCode(code);
    return g_code;
  };

  const resultObj = (text) => {
    const regex = /input\s+\w+\s+(\w+)\s*=\s*([^;]+);/g;
    let match;
    const result = {};

    while ((match = regex.exec(text)) !== null) {
      let value = match[2].trim();
      value = value.replace(/^"(.*)"$/, "$1");
      result[match[1]] = value;
    }

    return result;
  };

  const resultCode = async (optionsSummary) => {
    try {
      const result = await getGcode(optionsSummary);
      const resultobj = resultObj(result);
      const { Take_Profit, Stop_Loss, Lots, ...newData } = resultobj;
      setfinalObj(newData);
      return result;
    } catch (error) {
      console.error("Error fetching resultCode:", error);
    }
  };

  useEffect(() => {
    const generateOptionsSummary = () => {
      return {
        ...(initCode && { "On Init": initCode }),
        ...(tickCode && { "On Tick": tickCode }),
        ...(barCode && { "On Bar": barCode }),
      };
    };

    const fetchResultCode = async () => {
      const optionsSummary = generateOptionsSummary();
      // console.log("Options Summary:", optionsSummary);
      if (Object.keys(optionsSummary).length > 0) {
        await resultCode(optionsSummary);
      } else {
        console.warn("No options available to process in optionsSummary.");
      }
    };

    fetchResultCode();
  }, [initCode, tickCode, barCode, codeBlock, editCode]);

  return (
    <div className="backtest_main">
      <div className="conatiner">
        <div className="top_trade">
          <h3>Trading Options</h3>
        </div>

        <div className="robot_select">
          <div className=""> Select your robot :- </div>
          <Select
            onChange={handleWorkspaceChange}
            className="theme-text dropdown-wp "
            showArrow={true}
            value={currentName.length <= 0 ? UNTITLED_ROBOT : currentName}
          >
            {props.workspaceList.map((item, index) => (
              <Option key={`${item}${index}`} id={index} value={item.id}>
                {item.name}
              </Option>
            ))}
          </Select>
        </div>

        <div className="bt_form">
          <Form
            form={form}
            layout="vertical"
            onFinish={onFinish}
            initialValues={formValues}
            style={{ maxWidth: "100%", padding: "20px" }}
          >
            <Row gutter={[16, 16]}>
              <Col xs={24} sm={12} md={8}>
                <div className="option-style backtest-s " id="indicator-block">
                  <div className="indicator-field">
                    <div className="indicator-label">Trading Account</div>
                  </div>
                  <Form.Item
                    name="trading_account"
                    rules={[
                      {
                        required: true,
                        message: "Trading account is required",
                      },
                    ]}
                  >
                    <Select
                      className="theme-text indicator-select sym_select"
                      showArrow={true}
                    >
                      {lists &&
                        lists?.map((e) => (
                          <Option
                            key={e.trading_account_number}
                            value={e.trading_account_number}
                          >
                            {e.trading_account_number}
                          </Option>
                        ))}
                    </Select>
                  </Form.Item>
                </div>
              </Col>

              <Col xs={24} sm={12} md={8}>
                <div className="option-style backtest-s" id="indicator-block">
                  <div className="indicator-field">
                    <div className="indicator-label">Symbol</div>
                  </div>
                  <Form.Item
                    name="symbol"
                    rules={[{ required: true, message: "Symbol is required" }]}
                  >
                    <Select
                      className="theme-text indicator-select sym_select"
                      showArrow={true}
                    >
                      {symbolList &&
                        symbolList?.map((e) => (
                          <Option key={e.value} value={e.value}>
                            {e.label}
                          </Option>
                        ))}
                    </Select>
                  </Form.Item>
                </div>
              </Col>

              <Col xs={24} sm={12} md={8}>
                <div className="option-style  backtest-s" id="indicator-block">
                  <div className="indicator-field">
                    <div className="indicator-label">Timeframe</div>
                  </div>

                  <Form.Item
                    name="timeframe"
                    className="indicator-form-item"
                    rules={[
                      { required: true, message: "Timeframe is required" },
                    ]}
                  >
                    <Select
                      className="theme-text indicator-select sym_select"
                      showArrow={true}
                    >
                      <Option value="M30">M30</Option>
                      <Option value="M15">M15</Option>
                      <Option value="H1">H1</Option>
                      <Option value="H4">H4</Option>
                      <Option value="D1">D1</Option>
                    </Select>
                  </Form.Item>
                </div>
              </Col>

              <Col xs={24} sm={12} md={8}>
                <div className="option-style" id="indicator-block">
                  <div className="indicator-field">
                    <div className="indicator-label">Backtest Name</div>
                  </div>
                  <Form.Item
                    name="backtest_name"
                    rules={[
                      { required: true, message: "Backtest name is required" },
                    ]}
                  >
                    <Input className="text-input" />
                  </Form.Item>
                </div>
              </Col>

              <Col xs={24} sm={12} md={8}>
                <div className="option-style  backtest-s" id="indicator-block">
                  <div className="indicator-field">
                    <div className="indicator-label">Method</div>
                  </div>
                  <Form.Item
                    className="indicator-form-item"
                    name="method"
                    rules={[{ required: true, message: "Method is required" }]}
                  >
                    <Select
                      className="theme-text indicator-select sym_select"
                      showArrow={true}
                    >
                      <Option value="MODE_EMA">EXPONENTIAL</Option>
                      <Option value="MODE_SMA">SIMPLE</Option>
                      <Option value="MODE_SMMA">SMOOTHED</Option>
                      <Option value="MODE_LWMA">LINEAR_WEIGHTED</Option>
                    </Select>
                  </Form.Item>
                </div>
              </Col>

              <Col xs={24} sm={12} md={8}>
                <div className="option-style" id="indicator-block">
                  <div className="indicator-field">
                    <div className="indicator-label">Currency</div>
                  </div>
                  <Form.Item
                    name="currency"
                    rules={[
                      { required: true, message: "Currency is required" },
                      {
                        pattern: /^[A-Z]+$/,
                        message: "Currency must be in uppercase letters",
                      },
                    ]}
                  >
                    <Input className="text-input" />
                  </Form.Item>
                </div>
              </Col>

              <Col xs={24} sm={12} md={8}>
                <div className="option-style" id="indicator-block">
                  <div className="indicator-field">
                    <div className="indicator-label">Shift</div>
                  </div>
                  <Form.Item
                    name="shift"
                    rules={[{ required: true, message: "Shift is required" }]}
                  >
                    <Input type="number" className="text-input" />
                  </Form.Item>
                </div>
              </Col>

              <Col xs={24} sm={12} md={8}>
                <div className="option-style  backtest-s" id="indicator-block">
                  <div className="indicator-field">
                    <div className="indicator-label">Applied Price</div>
                  </div>
                  <Form.Item
                    className="indicator-form-item"
                    name="applied_price"
                    rules={[
                      { required: true, message: "Applied price is required" },
                    ]}
                  >
                    <Select
                      className="theme-text indicator-select sym_select"
                      showArrow={true}
                    >
                      <Option value="PRICE_OPEN">OPEN</Option>
                      <Option value="PRICE_CLOSE">CLOSE</Option>
                      <Option value="PRICE_HIGH">HIGH</Option>
                      <Option value="PRICE_LOW">LOW</Option>
                      <Option value="PRICE_MEDIAN">MEDIAN</Option>
                      <Option value="TYPICAL">TYPICAL</Option>
                      <Option value="PRICE_WEIGHTED">WEIGHTED</Option>
                    </Select>
                  </Form.Item>
                </div>
              </Col>

              <Col xs={24} sm={12} md={8}>
                <div className="option-style" id="indicator-block">
                  <div className="indicator-field">
                    <div className="indicator-label">Deposit</div>
                  </div>
                  <Form.Item
                    name="deposit"
                    rules={[
                      { required: true, message: "Deposit is required" },
                      {
                        validator: (_, value) => {
                          if (!value || value <= 0) {
                            return Promise.reject(
                              new Error("Deposit must be positive")
                            );
                          }
                          if (value === 5000) {
                            return Promise.reject(
                              new Error(
                                "5000 is not allowed as a deposit amount"
                              )
                            );
                          }
                          return Promise.resolve();
                        },
                      },
                    ]}
                  >
                    <Input type="number" className="text-input" />
                  </Form.Item>
                </div>
              </Col>

              <Col xs={24} sm={12} md={8}>
                <div className="option-style" id="indicator-block">
                  <div className="indicator-field">
                    <div className="indicator-label">From Date</div>
                  </div>
                  <Form.Item
                    name="from_date"
                    rules={[
                      { required: true, message: "From date is required" },
                    ]}
                  >
                    <DatePicker style={{ width: "100%", height: "50px" }} />
                  </Form.Item>
                </div>
              </Col>

              <Col xs={24} sm={12} md={8}>
                <div className="option-style" id="indicator-block">
                  <div className="indicator-field">
                    <div className="indicator-label">To Date</div>
                  </div>
                  <Form.Item
                    name="to_date"
                    rules={[{ required: true, message: "To date is required" }]}
                  >
                    <DatePicker style={{ width: "100%", height: "50px" }} />
                  </Form.Item>
                </div>
              </Col>

              <Col xs={24} sm={12} md={8}>
                <div className="option-style  backtest-s" id="indicator-block">
                  <div className="indicator-field">
                    <div className="indicator-label">Leverage</div>
                  </div>

                  <Form.Item
                    name="leverage"
                    rules={[
                      { required: true, message: "Leverage is required" },
                    ]}
                  >
                    <Select
                      className="theme-text indicator-select sym_select"
                      showArrow={true}
                    >
                      {ltag &&
                        ltag?.map((e) => (
                          <Option key={e.value} value={e.value}>
                            {e.label}
                          </Option>
                        ))}
                    </Select>
                  </Form.Item>
                </div>
              </Col>
            </Row>

            {finalList && (
              <>
                <Divider />
                <div className="editable_field">
                  {finalList.map((el, blockIdx) => {
                    const currentCodeBlock = codeBlock[blockIdx];
                    if (!currentCodeBlock) return null; // Skip if no matching codeBlock

                    return (
                      <div className="card_editable" key={blockIdx}>
                        {/* Title for the current code block */}
                        <h3 className="indicator-label">
                          {currentCodeBlock.name}
                        </h3>

                        {el.map((field, idx) => {
                          const fieldData = currentCodeBlock[field.NAME];
                          if (!fieldData) return null; // Skip if field doesn't exist in codeBlock

                          // Render SelectField for ENUM type
                          if (field.DATATYPE === INDICATOR_DATATYPES.ENUM) {
                            return (
                              <SelectField
                                key={`${blockIdx}-${field.NAME}`}
                                label={field.NAME}
                                data={field.DATA}
                                checked={fieldData?.ADJUSTABLE}
                                onCheckChange={(event) =>
                                  handleCheckChange(
                                    event.target.checked,
                                    field.NAME,
                                    blockIdx
                                  )
                                }
                                value={fieldData?.VALUE}
                                onChange={(key) =>
                                  handleFieldChange(
                                    key,
                                    field.NAME,
                                    blockIdx,
                                    field.REQUIRED
                                  )
                                }
                              />
                            );
                          }
                          if (
                            field.DATATYPE === INDICATOR_DATATYPES.STRING ||
                            field.DATATYPE === INDICATOR_DATATYPES.INTEGER ||
                            field.DATATYPE === INDICATOR_DATATYPES.DOUBLE
                          ) {
                            return (
                              <InputField
                                key={`${blockIdx}-${field.NAME}`}
                                label={field.NAME}
                                content={popOverContent[field.NAME]}
                                checked={fieldData?.ADJUSTABLE}
                                value={fieldData?.VALUE}
                                help={
                                  codeBlockError[field.NAME] &&
                                  codeBlockError[field.NAME].help
                                }
                                error={
                                  codeBlockError[field.NAME] &&
                                  codeBlockError[field.NAME].status
                                }
                                onChange={(event) =>
                                  handleFieldChange(
                                    event.target.value,
                                    field.NAME,
                                    blockIdx,
                                    field.REQUIRED
                                  )
                                }
                                onCheckChange={(event) =>
                                  handleCheckChange(
                                    event.target.checked,
                                    field.NAME,
                                    blockIdx
                                  )
                                }
                              />
                            );
                          }

                          return null;
                        })}
                      </div>
                    );
                  })}
                </div>
              </>
            )}

            <Row justify="center" style={{ marginTop: "20px" }}>
              <Col>
                <Button type="primary" htmlType="submit">
                  Submit
                </Button>
              </Col>
            </Row>
          </Form>
        </div>
      </div>
    </div>
  );
};

const mapDispatchToProps = {
  runDashboard: runDashboard,
  getWorkspace: getWorkspace,
  getAllWorkspaceList: getAllWorkspaceList,
  GlobalStates: GlobalStates,
  getAccList: getAccList,
};
const mapStateToProps = ({
  workspaceReducer: { workspaceData, workspaceList },
  userReducer: { accessToken, subscription },
}) => ({
  subscription,
  workspaceData,
  accessToken,
  workspaceList,
});

export default connect(mapStateToProps, mapDispatchToProps)(Backtest);
