'use strict';

import { BLOCKLY_DROPDOWN_INDICATOR } from '../../constants/constants.Indicator';
import { BLOCKLY_DROPDOWN_BAR } from '../../constants/constants.Bar';
import { BLOCKLY_DROPDOWN_ALERT } from '../../constants/constants';
import { SELL_OPTION_CODE_CONDITION, BUY_OPTIONS_CODE_CONDITION } from "../../constants/constants.buysell"

import Blockly from 'blockly';

Blockly.Blocks['Buy'] = {
  init: function () {
    //this.appendDummyInput()
    this.appendValueInput("Buy")
      .appendField("BUY");
    this.setOutput(true, null);
    this.setColour("#19ea6c");
    this.setTooltip("");
    this.setHelpUrl("");
  }
};

Blockly.Blocks['Sell'] = {
  init: function () {
    this.appendValueInput("Sell")
      //this.appendDummyInput()
      .appendField("SELL");
    this.setOutput(true, null);
    this.setColour("#db3737");
    this.setTooltip("");
    this.setHelpUrl("");
  }
};

Blockly.Blocks['Ask'] = {
  init: function () {
    this.appendValueInput("Ask")
      .appendField("Ask");
    //.setCheck(null);  
    //.appendField(new Blockly.FieldTextInput("Ask"), "ASK");
    this.setOutput(true, null);
    this.setColour("#19ea6c");
    this.setTooltip("");
    this.setHelpUrl("");
  }
};


Blockly.Blocks['Bid'] = {
  init: function () {
    this.appendValueInput("Bid")
      .appendField("Bid");
    //  .setCheck(null);
    this.setOutput(true, null);
    this.setColour("#db3737");
    this.setTooltip("");
    this.setHelpUrl("");
  }
};


Blockly.Blocks['week_filter'] = {
  init: function () {
    this.appendValueInput("OUTPUT 1")
      .setCheck(null)
      .appendField(new Blockly.FieldTextInput("Week"), "WEEK NAME")
    this.appendValueInput("OUTPUT 2")
      .setCheck(null)
    this.setOutput(true, null);
    this.setColour("#3046cc");
    this.setTooltip("");
    this.setHelpUrl("");
  }
};

Blockly.Blocks['time_filter'] = {
  init: function () {
    this.appendValueInput("OUTPUT 1")
      .setCheck(null)
      .appendField(new Blockly.FieldTextInput("Time"), "TIME HOUR")
    this.appendValueInput("OUTPUT 2")
      .setCheck(null)
    this.setOutput(true, null);
    this.setColour("#3046cc");
    this.setTooltip("");
    this.setHelpUrl("");
  }
};

Blockly.Blocks['indicator_filter'] = {
  init: function () {
    this.appendValueInput("Indicator")
      .setCheck(null)
      .appendField(new Blockly.FieldDropdown(BLOCKLY_DROPDOWN_INDICATOR), "Select Indicator");
    this.setOutput(true, null);
    this.setColour("#2e76cc");
    this.setTooltip("");
    this.setHelpUrl("");
  }
};

Blockly.Blocks['bar_filter'] = {
  init: function () {
    this.appendValueInput("Bar")
      .setCheck(null)
      .appendField(new Blockly.FieldDropdown(BLOCKLY_DROPDOWN_BAR), "Select Bar Type");
    this.setOutput(true, null);
    this.setColour("#21aff7");
    this.setTooltip("");
    this.setHelpUrl("");
  }
};

Blockly.Blocks['operator_filter'] = {
  init: function () {
    this.appendValueInput("Operator")
      .setCheck(null)
      .appendField(new Blockly.FieldDropdown([[">", ">"], ["<", "<"], ["<=", "<="], [">=", ">="], ["==", "=="], ["!=", "!="], ["&&", "&&"], ["||", "||"]]), "Operator");
    this.setOutput(true, null);
    this.setColour("#8d52cc");
    this.setTooltip("");
    this.setHelpUrl("");
  }
};

/*
Blockly.Blocks['Crosses_above'] = {
  init: function () {
    this.appendValueInput("text")
      .setCheck(null).appendField("Crosses Above");
    this.setOutput(true, null);
    this.setColour("#EF8570");
    this.setTooltip("");
    this.setHelpUrl("");
  }
};
Blockly.Blocks['Crosses_below'] = {
  init: function () {
    this.appendValueInput("text")
      .setCheck(null).appendField("Crosses Below");
    this.setOutput(true, null);
    this.setColour("#EF8570");
    this.setTooltip("");
    this.setHelpUrl("");
  }
};
*/

Blockly.Blocks['configure_filter'] = {
  init: function () {
    this.appendDummyInput()
      .appendField(new Blockly.FieldLabel("Configuration"), "NAME");
    this.setColour('#4a38a0');
    this.setTooltip("");
    this.setHelpUrl("");
    
  }
};
Blockly.Blocks['configure_filter_ai'] = {
  init: function () {
    this.appendDummyInput()
      .appendField(new Blockly.FieldLabel("AI Engine"), "NAME");
    this.setColour("#031438");
    this.setTooltip("");
    this.setHelpUrl("");
    this.customContextMenu = function (options) {
      const duplicateOptionIndex = options.findIndex(
        (option) => option.text === Blockly.Msg.DUPLICATE_BLOCK
      );
      if (duplicateOptionIndex !== -1) {
        options.splice(duplicateOptionIndex, 1);
      }
    };
  }
};

Blockly.Blocks['auto_money'] = {
  init: function () {
    this.appendDummyInput()
      .appendField("Auto Money Management");
    this.setColour("#e29423");
    this.setTooltip("");
    this.setHelpUrl("");
  }
};


Blockly.Blocks['trailing_stop'] = {
  init: function () {
    this.appendDummyInput()
      .appendField("Trailing Stop");
    //this.setOutput(true, null);
    this.setColour("#F7BC05");
    this.setTooltip("");
    this.setHelpUrl("");
  }
};

Blockly.Blocks['martingale'] = {
  init: function () {
    this.appendDummyInput()
      .appendField("Intelligent Martingale");
    this.setColour("#e29423");
    this.setTooltip("");
    this.setHelpUrl("");
  }
};

Blockly.Blocks['Alert'] = {
  init: function () {
    this.appendValueInput("Alert")
      //  .appendField("Alert");
      .appendField(new Blockly.FieldDropdown(BLOCKLY_DROPDOWN_ALERT), "Select Alert Type");
    this.setOutput(true, null);
    this.setColour("#F72605");
    this.setTooltip("");
    this.setHelpUrl("");
  }
};

Blockly.Blocks['Push_Notification'] = {
  init: function () {
    this.appendValueInput("Push Notification")
      .appendField("Push Notification");
    this.setOutput(true, null);
    this.setColour("#F72605");
    this.setTooltip("");
    this.setHelpUrl("");
  }
};

Blockly.Blocks['Email'] = {
  init: function () {
    this.appendValueInput("Email Alert")
      .appendField("Email Alert");
    this.setOutput(true, null);
    this.setColour("#F72605");
    this.setTooltip("");
    this.setHelpUrl("");
  }
};

Blockly.Blocks['Custom_Code'] = {
  init: function () {
    this.appendValueInput("Custom Code")
      .appendField("Custom Code");
    this.setOutput(true, null);
    this.setColour("#324768");
    this.setTooltip("");
    this.setHelpUrl("");
  }
};

Blockly.JavaScript['Custom_Code'] = function (block) {
  var code = `<<${block.id}>>`
  return [code, Blockly.JavaScript.ORDER_NONE];
};

Blockly.JavaScript['Buy'] = function (block) {
  var code = `<<${block.id}>>`
  return [code, Blockly.JavaScript.ORDER_NONE];
};

Blockly.JavaScript['Sell'] = function (block) {
  var code = `<<${block.id}>>`
  return [code, Blockly.JavaScript.ORDER_NONE];
};

Blockly.JavaScript['Alert'] = function (block) {
  //var code = recurseChildBlocks(block, ''); //'if [') || 'Print["Do Nothing"];'
  //return [code, Blockly.JavaScript.ORDER_NONE];
  var code = `<<${block.id}>>;`
  //return code;
  return [code, Blockly.JavaScript.ORDER_NONE];
};

Blockly.JavaScript['Push_Notification'] = function (block) {
  var code = `<<${block.id}>>`
  return [code, Blockly.JavaScript.ORDER_NONE];
};

Blockly.JavaScript['Email'] = function (block) {
  var code = `<<${block.id}>>`
  return [code, Blockly.JavaScript.ORDER_NONE];
};

Blockly.JavaScript['auto_money'] = function (block) {
  var code = `<<${block.id}>>`
  return code;
  //return [code, Blockly.JavaScript.ORDER_NONE];
};

Blockly.JavaScript['martingale'] = function (block) {
  var code = `<<${block.id}>>`
  return code;
  //return [code, Blockly.JavaScript.ORDER_NONE];
};

Blockly.JavaScript['trailing_stop'] = function (block) {
  var code = `<<${block.id}>>`
  return [code, Blockly.JavaScript.ORDER_NONE];
};

Blockly.JavaScript['week_filter'] = function (block) {
  var value_output_1 = Blockly.JavaScript.valueToCode(block, 'OUTPUT 1', Blockly.JavaScript.ORDER_ATOMIC) || '';
  var value_output_2 = Blockly.JavaScript.valueToCode(block, 'OUTPUT 2', Blockly.JavaScript.ORDER_ATOMIC) || '';
  var code = 'if [' + 'Options-<<' + block.id + '>> ]\n{\n' + '   ' + value_output_1 + '\n}\nelse\n{\n' + '   ' + value_output_2 + '\n}';
  return [code, Blockly.JavaScript.ORDER_NONE];
};

Blockly.JavaScript['time_filter'] = function (block) {
  var value_output_1 = Blockly.JavaScript.valueToCode(block, 'OUTPUT 1', Blockly.JavaScript.ORDER_ATOMIC) || '';
  var value_output_2 = Blockly.JavaScript.valueToCode(block, 'OUTPUT 2', Blockly.JavaScript.ORDER_ATOMIC) || '';
  var code = 'if [' + 'Options-<<' + block.id + '>> ]\n{\n' + '   ' + value_output_1 + '\n}\nelse\n{\n' + '   ' + value_output_2 + '\n}';
  return [code, Blockly.JavaScript.ORDER_NONE];
};

Blockly.JavaScript['operator_filter'] = function (block) {
  var dropdown_operator = block.getFieldValue('Operator');
  var code = `${dropdown_operator}`;
  return [code, Blockly.JavaScript.ORDER_NONE];
};

Blockly.JavaScript['bar_filter'] = function (block) {
  var code = recurseChildBlocks(block, 'if [') || 'Print["Do Nothing"];'
  return [code, Blockly.JavaScript.ORDER_NONE];
};

Blockly.JavaScript['Ask'] = function (block) {
  var code = recurseChildBlocks(block, 'if [') || 'Print["Do Nothing"];'
  return [code, Blockly.JavaScript.ORDER_NONE];
};

Blockly.JavaScript['Bid'] = function (block) {
  var code = recurseChildBlocks(block, 'if [') || 'Print["Do Nothing"];'
  return [code, Blockly.JavaScript.ORDER_NONE];
};

/*
Blockly.JavaScript['Crosses_below'] = function (block) {
  var dropdown_operator = block.getFieldValue('input');
  var code = `${dropdown_operator}`;
  return [code, Blockly.JavaScript.ORDER_NONE];
};
Blockly.JavaScript['Crosses_above'] = function (block) {
  var dropdown_operator = block.getFieldValue('input');
  var code = `${dropdown_operator}`;
  return [code, Blockly.JavaScript.ORDER_NONE];
};
*/


Blockly.JavaScript['indicator_filter'] = function (block) {
  var code = recurseChildBlocks(block, 'if [') || 'Print["Do Nothing"];'
  return [code, Blockly.JavaScript.ORDER_NONE];
};

Blockly.JavaScript['configure_filter'] = function (block) {
  var code = `<<${block.id}>>`;
  return code;
};
Blockly.JavaScript['configure_filter_ai'] = function (block) {
  var code = `<<${block.id}>>`;
  return code;
};


/**
 * @description recurse through all the child of indicator filters
 * @returns generated code
 */
const recurseChildBlocks = (block, code) => {
  if (!block) return code;

  if (block.type === 'indicator_filter') {

        if (block.childBlocks_ && block.childBlocks_[0]) {

            var value = Blockly.JavaScript.valueToCode(block, 'Indicator', Blockly.JavaScript.ORDER_ATOMIC);

            const tempStr = code + (block.childBlocks_[0].type === 'operator_filter'
                ? ` <<${block.id}>> ${value} `
                : block.childBlocks_[0].type !== 'indicator_filter' && block.childBlocks_[0].type !== 'bar_filter'
                    && block.childBlocks_[0].type !== 'Ask' && block.childBlocks_[0].type !== 'Bid'
                    ? ` <<${block.id}>> ${block?.workspace?.typedBlocksDB_?.configure_filter_ai ? block.childBlocks_[0].type === 'Buy' ? block?.workspace?.typedBlocksDB_?.Buy ? BUY_OPTIONS_CODE_CONDITION : '' : block?.workspace?.typedBlocksDB_?.Sell ? SELL_OPTION_CODE_CONDITION : '' : ''}]\n{\n ${value} \n}\n` : '')

            // console.log("tempstr1 is " + tempStr)

            return recurseChildBlocks(block.childBlocks_[0], tempStr)
        }
        const tempStr = code + ` <<${block.id}>> ]\n{\n ${'Print["Do Nothing"]SEMICOLON'} \n}\n`

        return recurseChildBlocks(block.childBlocks_[0], tempStr)
    }
  
  if (block.type === 'bar_filter') {
    //console.log("reached here1")
    if (block.childBlocks_ && block.childBlocks_[0]) {
      value = Blockly.JavaScript.valueToCode(block, 'Bar', Blockly.JavaScript.ORDER_ATOMIC);
      //console.log("value is  " + value)
      const tempStr = code + (block.childBlocks_[0].type === 'operator_filter'
        ? ` <<${block.id}>> ${value} `
        : block.childBlocks_[0].type !== 'indicator_filter' && block.childBlocks_[0].type !== 'bar_filter'
          && block.childBlocks_[0].type !== 'Ask' && block.childBlocks_[0].type !== 'Bid'
          ? ` <<${block.id}>> ]\n{\n ${value} \n}\n` : '')
      //console.log("tempstr1 is " + tempStr)
      return recurseChildBlocks(block.childBlocks_[0], tempStr)
    }
    const tempStr = code + ` <<${block.id}>> ]\n{\n ${'Print["Do Nothing"];'} \n}\n`
    return recurseChildBlocks(block.childBlocks_[0], tempStr)
  }

  if (block.type === 'Alert') {
    //console.log('Reached Alert');
    if (block.childBlocks_ && block.childBlocks_[0]) {
      //console.log("reached here1")
      var value = Blockly.JavaScript.valueToCode(block, 'Alert', Blockly.JavaScript.ORDER_ATOMIC);
      console.log('alert value is ' + value)
      const tempStr = code + (block.childBlocks_[0].type === 'operator_filter'
        ? ` <<${block.id}>> ${value} `
        : block.childBlocks_[0].type !== 'indicator_filter' && block.childBlocks_[0].type !== 'bar_filter'
          && block.childBlocks_[0].type !== 'Ask' && block.childBlocks_[0].type !== 'Bid'
          ? ` <<${block.id}>> ]\n{\n ${value} \n}\n` : '')
      //console.log("tempstr1 is " + tempStr)
      return recurseChildBlocks(block.childBlocks_[0], tempStr)
    }
    //console.log("reached here2")
    const tempStr = code //+ ` <<${block.id}>>;`
    return recurseChildBlocks(block.childBlocks_[0], tempStr)
  }
  /*
  if (block.type === 'Alert') 
  {
     console.log('yo');
     code = code + ` <<${block.id}>>;`
  }
  */
  //const tempStr = code + 'blah1;';
  //recurseChildBlocks(block.childBlocks_[0], tempStr);

  /*
  if (block.type === 'Alert') {
    //console.log("reached alert 1here1")
    if (block.childBlocks_ && block.childBlocks_[0]) {
      value = Blockly.JavaScript.valueToCode(block, 'Alert', Blockly.JavaScript.ORDER_ATOMIC);
      //console.log("value is  " + value)
      const tempStr = code + (block.childBlocks_[0].type === 'operator_filter'
        ? ` <<${block.id}>> ${value} `
        : block.childBlocks_[0].type !== 'indicator_filter' &&  block.childBlocks_[0].type !== 'bar_filter'
        &&  block.childBlocks_[0].type !== 'Ask' &&  block.childBlocks_[0].type !== 'Bid'
          ? ` <<${block.id}>> ]\n{\n ${value} \n}\n` : '')
    console.log("alert1 is " + tempStr)
      return recurseChildBlocks(block.childBlocks_[0], tempStr)
    }
    //value = Blockly.JavaScript.valueToCode(block, 'Alert', Blockly.JavaScript.ORDER_ATOMIC);
    const tempStr = code + <<${block.id}>>;  //]\n{\n ${'Print["Do Nothing"];'} \n}\n`
    return recurseChildBlocks(block.childBlocks_[0], tempStr)
  }
  */
  if (block.type === 'Ask') {
    //console.log("reached ask")
    if (block.childBlocks_ && block.childBlocks_[0]) {
      value = Blockly.JavaScript.valueToCode(block, 'Ask', Blockly.JavaScript.ORDER_ATOMIC);
      //console.log("value  Ask is  " + value)
      const tempStr = code + (block.childBlocks_[0].type === 'operator_filter'
        ? ` <<${block.id}>> ${value} `
        : block.childBlocks_[0].type !== 'indicator_filter' && block.childBlocks_[0].type !== 'bar_filter'
          && block.childBlocks_[0].type !== 'Ask' && block.childBlocks_[0].type !== 'Bid'
          ? ` <<${block.id}>> ]\n{\n ${value} \n}\n` : '')
      //console.log("tempstr1 is " + tempStr)
      return recurseChildBlocks(block.childBlocks_[0], tempStr)
    }
    const tempStr = code + ` <<${block.id}>> ]\n{\n ${'Print["Do Nothing"];'} \n}\n`
    return recurseChildBlocks(block.childBlocks_[0], tempStr)
  }
  if (block.type === 'Bid') {
    //console.log("reached here2")
    if (block.childBlocks_ && block.childBlocks_[0]) {
      value = Blockly.JavaScript.valueToCode(block, 'Bid', Blockly.JavaScript.ORDER_ATOMIC);
      //console.log("value Bid is  " + value)
      const tempStr = code + (block.childBlocks_[0].type === 'operator_filter'
        ? ` <<${block.id}>> ${value} `
        : block.childBlocks_[0].type !== 'indicator_filter' && block.childBlocks_[0].type !== 'bar_filter'
          && block.childBlocks_[0].type !== 'Ask' && block.childBlocks_[0].type !== 'Bid'
          ? ` <<${block.id}>> ]\n{\n ${value} \n}\n` : '')
      //console.log("tempstr1 is " + tempStr)
      return recurseChildBlocks(block.childBlocks_[0], tempStr)
    }
    const tempStr = code + ` <<${block.id}>> ]\n{\n ${'Print["Do Nothing"];'} \n}\n`
    return recurseChildBlocks(block.childBlocks_[0], tempStr)
  }
  if (block.type === 'operator_filter') {
    return (!block.childBlocks_[0] || (block.childBlocks_[0].type !== 'indicator_filter' && block.childBlocks_[0].type !== 'bar_filter'
      && block.childBlocks_[0].type !== 'Ask' && block.childBlocks_[0].type !== 'Bid'))
      ? recurseChildBlocks(block.childBlocks_[0], '')
      : recurseChildBlocks(block.childBlocks_[0], code)
  }
 
  return code;
}


