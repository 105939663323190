import React, { useEffect, useState } from "react";
import { Rate, Typography, Button, Modal, Input, message } from "antd";
import {
  CANCEL_ROBOT_SUBSCRIPTION,
  CANCEL_SUBSCRIPTION_BUTTON,
  EDIT_BUTTON,
  DOWNLOAD_BUTTON,
  PER_MONTH,
  SUCCESS,
  ACCOUNT_UPDATED,
  DOLLAR,
  SAVE_RATING,
  ERROR,
  DOWNLOAD_BUTTON_EX5,
  DOWNLOAD_BUTTON_EX4,
} from "../../constants/messages";
import { ImageBase } from "../../constants/APIConstant";
import no_Image from "../../assets/no_Image.png";
import {
  DownloadOutlined,
  CloseCircleOutlined,
  EditOutlined,
} from "@ant-design/icons";
import { Tooltip } from "antd";
import deleteimg from "../../assets/robotasset/Delete.png";
import editing from "../../assets/robotasset/Edit.png";

export default function NewsubRobotCard(props) {
  const { item, userData, accessToken, parentUpdate, imageSize } = props;

  const { Text, Title } = Typography;
  const { TextArea } = Input;
  const [cancelSubModel, setCancelSubModel] = useState(false);
  const [editSubsModel, setEditSubModel] = useState(false);
  const [saveload, setsaveload] = useState(false);
  const [accload, setaccload] = useState(false);
  const [account_1, setAccount_1] = useState("");
  const [account_2, setAccount_2] = useState("");
  const [ratingStar, setRatingStar] = useState(null);
  const [commentArea, setCommentArea] = useState("");

  useEffect(() => {
    if (item) {
      setRatingStar(item.user_rating);
      setCommentArea(item.user_rating_reviews);
      setAccount_1(item.account_no1);
      setAccount_2(item.account_no2);
    }
  }, [props.item?.copy_robot_id]);
  const handleCancelSubscription = () => {
    setCancelSubModel(true);
  };
  const cancelSubClick = () => {
    setCancelSubModel(false);
    props.cancelRobotSubscriptionApi({
      id: item.copy_robot_id,
      accessToken: accessToken,
      callback: (info, data) => {
        if (info === SUCCESS) {
          message.success(CANCEL_ROBOT_SUBSCRIPTION);
          parentUpdate();
        } else {
          message.error(data);
        }
      },
    });
  };
  const handleBrokerAccountEdit = () => {
    setaccload(true);
    props.postBrokerAccountApi({
      data: {
        robot_id: item.copy_robot_id,
        account_no1: account_1,
        account_no2: account_2,
        robot_owner_id: item.robot_owner_id,
      },
      accessToken: accessToken,
      callback: (info, data) => {
        setaccload(false);
        if (info === SUCCESS) {
          message.success(ACCOUNT_UPDATED);
          setEditSubModel(false);
        } else {
          message.error(data);
        }
      },
    });
  };
  const handleRatingChange = (e) => {
    setRatingStar(e);
  };
  const handleCommmentChange = (e) => {
    setCommentArea(e.target.value);
  };
  const handleSaveComment = () => {
    if (commentArea && ratingStar) {
      setsaveload(true);
      props.postRobotRating({
        id: item?.copy_robot_id,
        user_id: userData.user_id,
        data: {
          rating: ratingStar,
          rating_comment: commentArea,
        },
        accessToken: accessToken,
        callback: (info, data) => {
          setsaveload(false);
          if (info === SUCCESS) {
            message.success("Rating and comment save");
          } else {
            message.error(data);
          }
        },
      });
    }
  };
  const handleDownloadFile = (type) => {
    const { accessToken } = props;

    props.downloadFile({
      accessToken: accessToken,
      id: item.copy_robot_id,
      type: type,
      name: item.robot_name,
      data: {
        file_type: type,
        // global_code: ""
      },
    });
  };
  const style = {
    width: "320px",
    height: "250px",
    backgroundSize: 'cover',
    backgroundRepeat: 'no-repeat',
    backgroundPosition: 'center center',
  };
  if (item?.image1) {
    style.backgroundImage = `url(${ImageBase.dev + item?.image1})`;
  } else {
    style.backgroundImage = `url(${no_Image})`;
  }
  return (
    <>
      <div className=" subcard">
        <div style={style} className="image-set-back zoom-img"></div>
        <div className="cardStyle div-pad">
          <div className="">
            <div className="column text-left">
              <Typography className="t-subrobot">
                {item?.robot_name}
              </Typography>
            </div>
            <div className="row">
            <Rate
                value={ratingStar}
                style={{ color: "#EDA830" }}
                onChange={handleRatingChange}
              />
              <Typography style={{ fontWeight: "900" }}>
                <Text className="font price_card">
                      
                      <sup>{DOLLAR} </sup>
                      {item?.robot_monthly_price}
                      <span>{PER_MONTH}</span>
                    </Text>
              </Typography>
              
            </div>
          </div>
          <div className="row mrTop alignnone">
            <TextArea
              value={commentArea}
              onChange={handleCommmentChange}
              placeholder="Comment"
              autoSize={{ minRows: 5, maxRows: 5 }}
              className="widthFull text-dark b-5 inputBox-style"
            />
          </div>

          <div className="row mrTop">
            {commentArea && ratingStar ? (
              saveload ? (
                <Button
                  type="primary"
                  className="button primary width-full btns  "
                >
                  Loading...
                </Button>
              ) : (
                <Button
                  type="primary"
                  className="button primary width-full btns  "
                  onClick={handleSaveComment}
                >
                  {SAVE_RATING}
                </Button>
              )
            ) : null}
            <Tooltip title={DOWNLOAD_BUTTON_EX4}>
              <Button
                type="primary"
                className="button primary width-full btns  "
                onClick={() => handleDownloadFile("ex4")}
              >
                .ex4
                <DownloadOutlined />
              </Button>
            </Tooltip>
            <Tooltip title={DOWNLOAD_BUTTON_EX5}>
              <Button
                type="primary"
                className="button primary width-full btns b-5"
                onClick={() => handleDownloadFile("ex5")}
              >
                .ex5
                <DownloadOutlined />
              </Button>
            </Tooltip>
          </div>
          <div className="bottom_btn">
            <Tooltip title={CANCEL_SUBSCRIPTION_BUTTON}>
              <Button
                type="primary"
                className="button primary width-full  b-5 white_btn"
                onClick={handleCancelSubscription}
              >
                {/* <CloseCircleOutlined /> */}
                <img src={deleteimg} alt="" />
              </Button>
            </Tooltip>
            <Tooltip title={EDIT_BUTTON}>
              <Button
                type="primary"
                className="button primary width-full  b-5 white_btn"
                onClick={() => setEditSubModel(true)}
              >
                <img src={editing} alt="" />
              </Button>
            </Tooltip>
          </div>
        </div>
      </div>
      <Modal
        visible={cancelSubModel}
        title="Cancel Subscription"
        centered
        onOk={() => {}}
        onCancel={() => setCancelSubModel(false)}
        footer={[
          <>
            <Button
              key="submit"
              className="cancel_btn b-5"
              type="primary"
              onClick={() => setCancelSubModel(false)}
            >
              Cancel
            </Button>
            <Button
              className="btns b-5"
              type="primary"
              onClick={cancelSubClick}
            >
              Confirm
            </Button>
          </>,
        ]}
      >
        <div className="text-center">
          <Title level={3}>Are you sure?</Title>
          <Title style={{ margin: "0px" }} level={3}>
            (You will lose access immediately)
          </Title>
        </div>
      </Modal>
      <Modal
        title="Edit Subscription"
        centered
        visible={editSubsModel}
        onCancel={() => setEditSubModel(false)}
        // width={1000}
        footer={[
          <Button
            type="primary"
            onClick={accload ? "" : handleBrokerAccountEdit}
            className="btns b-5"
          >
            {accload ? "Loading..." : "save"}
          </Button>,
        ]}
      >
        <div className="alignCard">
          <div className="alignFiled">
            <Text strong>Authorised Broker Account No. 1</Text>
            <Input
              placeholder="123445"
              value={account_1}
              onChange={(e) => setAccount_1(e.target.value)}
            />
          </div>
          <div className="alignFiled">
            <Text strong>Authorised Broker Account No. 2</Text>
            <Input
              placeholder="123445"
              value={account_2}
              onChange={(e) => setAccount_2(e.target.value)}
            />
          </div>
        </div>
      </Modal>
    </>
  );
}
