// ForecastTable.js
import React, { useEffect, useLayoutEffect, useMemo, useState } from "react";
import { useTable, usePagination, useSortBy } from "react-table";
// import "react-datepicker/dist/react-datepicker.css";
import "./ai.css";
import {  DatePicker,Space, Select, Input, Button, message, Modal } from "antd";
import Axios from "axios";
import { ERROR, MOBILE_CONTENT, MOBILE_HEADER, SUCCESS } from "../../constants/messages";
import { connect } from "react-redux";
import { AIforcast } from "../../actions/workspaceActions";
import moment from "moment";
import Up from '../../assets/robotasset/up.png'
import Down from '../../assets/robotasset/down.png'
import Line from '../../assets/robotasset/icon-line.png'
import DateTimeComponent from "./DateTimeComponent";
import Swap from "../../assets/robotasset/Swap.png";
import Ace from "../../assets/robotasset/asc.png";
import Dec from "../../assets/robotasset/dec.png";



function ForecastTable(props) {
  const [date, setDate] = useState(null);
  const [timeframe, setTimeframe] = useState("");
  const [symbol, setSymbol] = useState("");
  const [disableSearchBy,setdisableSearchBy]=useState(false);
  const [searchString, setSearchString] = useState("");
  const [pagecount, setpagecount] = useState(1);
  const [sortBy, setSortBy] = useState("");

  const [records, setrecords] = useState([]);
  const [totalrecord, settotalrecord] = useState(0);
  const [searchbtn, setsearchbtn] = useState(true);
 
  const [refresh, setrefresh] = useState(false);
  const [mobileWarning, setMobileWarning] = useState(false);
  const [sortOrder, setSortOrder] = useState({
    forecast_datetime: "asc",
    forecast_high_price: "asc",
    forecast_low_price: "asc",
    forecast_open_price: "asc",
    forecast_close_price: "asc",
    timeframe: "asc",
    forecast_direction: "asc",
  });

  
  useEffect(()=>{
    if(symbol!=="")
    {
      setSearchString("");
      setdisableSearchBy(true)
    } else{
      setdisableSearchBy(false)
    }
  },[symbol]);
  
  useEffect(()=>{
      setdisableSearchBy(false)
  },[]);

  const handleFilter = (key) => {
    if(key==='search'){
      setpagecount(1)
    }
    if (date !== "" || timeframe !== "" || symbol !== "" || searchString) {
      const params = {};
      if (date) params.forecast_datetime =moment(date).format("YYYY-MM-DDTHH:mm:ss") + "Z" ;
      if (timeframe) params.timeframe = timeframe;
      if (symbol) params.symbol_name = symbol;
      if (searchString) params.search_query = searchString;
      if (pagecount) params.page = pagecount;
      if (sortBy) params.sort_by = sortBy;
     if (sortOrder[sortBy]) params.sort_order = sortOrder[sortBy];
      const accessToken=localStorage.getItem('token')
      props.AIforcast({
        data: params,
        accessToken,
        callback: (status, info) => {
          if(status===SUCCESS){
            setrecords(info.results);
            settotalrecord(info.count);
            if(info.count===0){
              setpagecount(0)
            }
          }
          if (status === ERROR) {
            message.error(info)
            setrecords([])
            settotalrecord(0)
            setpagecount(0)
            if(info=== "Token is Expired"){
            console.log(info)
            handleFilter()
            }
            
            return;
          }

        },
      });

    } else {
      setsearchbtn(true);
    }
  };

  const handleSort = (columnId) => {
    setSortBy(columnId);
    setSortOrder((prevOrder) => {
      const newOrder = prevOrder[columnId] === "asc" ? "desc" : "asc";
      return { ...prevOrder, [columnId]: newOrder };
    });
    handleFilter(); 
  };
 
  useEffect(() => {
    function updateSize() {
      setMobileWarning(window.innerWidth < 700);
    }
    window.addEventListener("resize", updateSize);
    updateSize();
    return () => { 
      window.removeEventListener("resize", updateSize);
    };
  }, []);

  useEffect(() => {
    handleFilter();  
  }, [sortBy, sortOrder, pagecount, refresh]); 
  

  const handleFilterReset = () => {
    setDate(null);
    setTimeframe("");
    setSymbol("");
    setSearchString("");
    setpagecount(1);
    setrefresh(!refresh)
    setSortBy("");
  };

  const initailCall=()=>{
    const params = {};
    if (pagecount) params.page = pagecount;
    const accessToken=localStorage.getItem('token')
      props.AIforcast({
      data: params,
      accessToken,
      callback: (status, info) => {
        if(status===SUCCESS){
          setrecords(info.results|| []);
          settotalrecord(info.count);
        }
        if (status === ERROR) {
          console.log(info);
          if(info=== "Token is Expired"){
          console.log(info)
          initailCall()
          }
          return;
        }
      },
    });
  }
  useEffect(() => {
    // initailCall()
  }, []);

  useEffect(() => {
    if (date !== "" || timeframe !== "" || symbol !== "" || searchString) {
      setsearchbtn(false);
    }
  }, [date, timeframe, symbol, searchString]);

  const data = useMemo(() => records, [records]);

  const formatValue = (value, symbol) => {
    if (!value) return value;
    if (symbol.includes("JPY")) {
      return parseFloat(value).toFixed(3);
    }
    const twoDecimalSymbols = [
      "US30", "US500", "BTCUSD", "XAUUSD", "XAGUSD", "ETHUSD",
      "GER40", "UK100", "XTIUSD"
    ];
    if (twoDecimalSymbols.includes(symbol)) {
      return parseFloat(value).toFixed(2);
    }
    return parseFloat(value).toFixed(5);
  };

  
  const columns = useMemo(
    () => [
      { Header: "Instruments", accessor: "symbol_name" },
      {  Header: () => (
        <div onClick={() => handleSort("timeframe",sortBy)}>
          Timeframe{" "}
          <img
            style={{ height: "15px" }}
            src={sortBy===""?Swap:sortBy==='timeframe'? sortOrder.timeframe==='asc'? Ace :Dec:Swap}
            alt="img"
          />
        </div>
      ), accessor: "timeframe" },
      {
        Header: () => (
          <div onClick={() => handleSort("forecast_datetime",sortBy)}>
            Forecast Date/Time{" "}
            <img
              style={{ height: "15px" }}
              src={sortBy===""?Swap:sortBy==='forecast_datetime'? sortOrder.forecast_datetime==='asc'? Ace :Dec:Swap}
              alt="img"
            />
          </div>
        ),
        accessor: "forecast_datetime",
        // sortType: "datetime",
      },
      {
        Header: () => (
          <div onClick={() => handleSort("forecast_high_price",sortBy)}>
            High{" "}
            <img
              style={{ height: "15px" }}
              src={sortBy===""?Swap:sortBy==='forecast_high_price'? sortOrder.forecast_high_price==='asc'? Ace :Dec:Swap}
              alt="img"
            />
          </div>
        ),
        accessor: "forecast_high_price",
        Cell: ({ row: { original } }) => (
          formatValue(original.forecast_high_price, original.symbol_name)
        ),
      },
      {
        Header: () => (
          <div onClick={() => handleSort("forecast_low_price")}>
            Low{" "}
            <img
              style={{ height: "15px" }}
              src={sortBy===""?Swap:sortBy==='forecast_low_price'? sortOrder.forecast_low_price==='asc'? Ace :Dec:Swap}
              alt="img"
            />
          </div>
        ),
        accessor: "forecast_low_price",
        Cell: ({ row: { original } }) => (
          formatValue(original.forecast_low_price, original.symbol_name)
        ),
      },
      {
        Header: () => (
          <div onClick={() => handleSort("forecast_open_price")}>
            Open{" "}
            <img
              style={{ height: "15px" }}
              src={sortBy===""?Swap:sortBy==='forecast_open_price'? sortOrder.forecast_open_price==='asc'? Ace :Dec:Swap}
              alt="img"
            />
          </div>
        ),
        accessor: "forecast_open_price",
        Cell: ({ row: { original } }) => (
          formatValue(original.forecast_open_price, original.symbol_name)
        ),
      },
      {
        Header: () => (
          <div onClick={() => handleSort("forecast_close_price")}>
            Close{" "}
            <img
              style={{ height: "15px" }}
              src={sortBy===""?Swap:sortBy==='forecast_close_price'? sortOrder.forecast_close_price==='asc'? Ace :Dec:Swap}
              alt="img"
            />
          </div>
        ),
        accessor: "forecast_close_price",
        Cell: ({ row: { original } }) => (
          formatValue(original.forecast_close_price, original.symbol_name)
        ),
      },
      {
        Header: () => (
          <div onClick={() => handleSort("forecast_direction")}>
            Direction{" "}
            <img
              style={{ height: "15px" }}
              src={sortBy===""?Swap:sortBy==='forecast_direction'? sortOrder.forecast_direction==='asc'? Ace :Dec:Swap}
              alt="img"
            />
          </div>
        ),
        accessor: "forecast_direction",
        Cell: ({ value }) => (
          <div style={{ color: value === "UP" ? "green" :value ==='SIDEWAYS'?'#ffc800': "red" }}>{value} <img src={value === "UP" ? Up :value ==='SIDEWAYS'?Line: Down} alt="" /></div>
        ),
      },
    ],
    [sortOrder,sortBy]
  );

  const {
    getTableProps,
    getTableBodyProps,
    headerGroups,
    prepareRow,
    page,
    canPreviousPage,
    canNextPage,
    pageOptions,
    pageCount,
    gotoPage,
    nextPage,
    previousPage,
    state: { pageIndex },
  } = useTable(
    {
      columns,
      data,
      manualPagination: true,
      pageCount: Math.ceil(totalrecord / 10),
    },
    useSortBy,
    usePagination
  );
  const handleChangeselect = (value) => {
    setSymbol(value);
  };
  const handleChangetimeframe = (value) => {
    setTimeframe(value);
  };

  const searchText = (e, dateString) => {
    setSearchString(e.target.value);
  };


  return (
    <div className="table_pannel">
      <div className="" style={{ padding: "30px", background: "#fff" }}>
        <div className="search_pannel">
          <div className="filter_block">
            <p className="filter-label">symbol name</p>
            <Select
            showSearch
            optionFilterProp="children"
            filterOption={(input, option) => (option?.label ?? '').includes(input.toUpperCase())}
            filterSort={(optionA, optionB) =>
              (optionA?.label ?? '').toUpperCase().localeCompare((optionB?.label ?? '').toUpperCase())
            }
              value={symbol === "" ? null : symbol}
              placeholder="Select symbol"
              className="sym_select"
              onChange={handleChangeselect}
              options={[
                { value: "CADJPY", label: "CADJPY" },
                { value: "USDJPY", label: "USDJPY" },
                { value: "AUDUSD", label: "AUDUSD" },
                { value: "EURCAD", label: "EURCAD" },
                { value: "EURCHF", label: "EURCHF" },
                { value: "AUDNZD", label: "AUDNZD" },
                { value: "EURAUD", label: "EURAUD" },
                { value: "EURJPY", label: "EURJPY" },
                { value: "GER40", label: "GER40" },
                { value: "US500", label: "US500" },
                { value: "GBPNZD", label: "GBPNZD" },
                { value: "EURUSD", label: "EURUSD" },
                { value: "NZDUSD", label: "NZDUSD" },
                { value: "USDCAD", label: "USDCAD" },
                { value: "AUDCAD", label: "AUDCAD" },
                { value: "UK100", label: "UK100" },
                { value: "XAGUSD", label: "XAGUSD" },
                { value: "EURNZD", label: "EURNZD" },
                { value: "GBPCHF", label: "GBPCHF" },
                { value: "XTIUSD", label: "XTIUSD" },
                { value: "CHFJPY", label: "CHFJPY" },
                { value: "NZDCAD", label: "NZDCAD" },
                { value: "GBPAUD", label: "GBPAUD" },
                { value: "BTCUSD", label: "BTCUSD" },
                { value: "NZDCHF", label: "NZDCHF" },
                { value: "US30", label: "US30" },
                { value: "NZDJPY", label: "NZDJPY" },
                { value: "CADCHF", label: "CADCHF" },
                { value: "XAUUSD", label: "XAUUSD" },
                { value: "USDCHF", label: "USDCHF" },
                { value: "EURGBP", label: "EURGBP" },
                { value: "GBPCAD", label: "GBPCAD" },
                { value: "GBPJPY", label: "GBPJPY" },
                { value: "AUDJPY", label: "AUDJPY" },
                { value: "GBPUSD", label: "GBPUSD" },
                { value: "ETHUSD", label: "ETHUSD" },
              ]}
            />
          </div>
          <div className="filter_block">
            <p className="filter-label">Select datetime</p>
            <Space direction="vertical">
              {/* <DatePicker
                showTime={{
                  format: "HH:mm",
                  defaultValue: moment("00:00", "HH:mm"),
                }}
                className="date-pick"
                format="YYYY-MM-DD HH:mm:ss"
                suffixIcon={
                  <img
                    src={require("../../assets/robotasset/Calendar.png")}
                    alt="date icon"
                  />
                }
                open={open}
                onChange={(value) => handleDate(value)}
                onOk={handleOk}
                onOpenChange={handleOpenChange}
                
              /> */}
              <DateTimeComponent setDate={setDate} date={date} />
            </Space>
          </div>
          <div className="filter_block">
            <p className="filter-label">Timeframe</p>
            <Select
            value={timeframe === "" ? null : timeframe}
              className="sym_select"
              placeholder="Select timeframe"
              onChange={handleChangetimeframe}
              options={[
                { value: "M30", label: "M30" },
                { value: "M15", label: "M15" },
                { value: "H1", label: "H1" },
                { value: "H4", label: "H4" },
                { value: "D1", label: "D1" },
              ]}
            />
          </div>
          <div className="filter_block">
            <p className="filter-label">Search by</p>
            <Input
               value={searchString}
              className="Sinput"
              disabled={disableSearchBy}
              placeholder="Search..."
              suffix={
                <img
                  className="searchimg"
                  src={require("../../assets/robotasset/Search.png")}
                  alt="date icon"
                />
              }
              onChange={searchText}
              onKeyDown={(event)=>{
                if(event.key === "Enter"){
                  handleFilter();
                }

              }}
            />
          </div>
          <div className="filter_block btn-grp">
            <Button
              disabled={searchbtn}
              type="primary btns srch_btn"
              onClick={()=>handleFilter('search')}
            >
              Search
            </Button>
            <Button
              type="primary btns srch_btn"
              onClick={handleFilterReset}
            >
             Reset
            </Button>
          </div>
        </div>
        <div className="filter_pannel">
          <table
            {...getTableProps()}
            style={{
              width: "100%",
              borderCollapse: "collapse",
              border: "solid 1px #DBDCDE",
            }}
          >
            <thead>
              {headerGroups.map((headerGroup) => (
                <tr {...headerGroup.getHeaderGroupProps()}>
                  {headerGroup.headers.map((column) => (
                    <th
                      {...column.getHeaderProps(column.getSortByToggleProps())}
                      style={{
                        padding: "15px",
                        background: "var(--primary-color)",
                        color: "#fff",
                        fontWeight: "bold",
                        cursor: "pointer",
                        textTransform: "uppercase",
                        textAlign: "center",
                        fontSize: "15px",
                      }}
                    >
                      {column.render("Header")}
                    </th>
                  ))}
                </tr>
              ))}
            </thead>
            <tbody {...getTableBodyProps()}>
              {page.map((row) => {
                prepareRow(row);
                return (
                  <tr {...row.getRowProps()}>
                    {row.cells.map((cell) => (
                      <td
                        {...cell.getCellProps()}
                        style={{
                          padding: "10px 15px",
                          borderBottom: "solid 1px var(--bordercolor)",
                          background: "#fff",
                          textAlign: "center",
                          fontWeight: "700",
                          fontSize: "14px",
                        }}
                      >
                        {cell.render("Cell")}
                      </td>
                    ))}
                  </tr>
                );
              })}
            </tbody>
          </table>
          <div className="pagination">
            <button
              className="pbtn fbtn"
              onClick={() => setpagecount(1)}
              disabled={pagecount <= 0}
            >
              <img
                src={require("../../assets/robotasset/firstarroe.png")}
                alt=""
              />
              first
            </button>{" "}
            <button
              className="pbtn fbtn"
              onClick={() => setpagecount(pagecount > 1 ? pagecount - 1 : 1)}
              disabled={pagecount <= 0}
            >
              <img
                src={require("../../assets/robotasset/backarrow.png")}
                alt=""
              />
              back
            </button>{" "}
            {/* {renderPageNumbers()} */}
            <button
              className="pbtn lbtn"
              onClick={() => setpagecount(pagecount + 1)}
              disabled={pagecount===pageOptions.length}
            >
              next
              <img src={require("../../assets/robotasset/prarr.png")} alt="" />
            </button>{" "}
            <button
              onClick={() => setpagecount(Math.ceil(totalrecord / 10))}
              disabled={pagecount===pageOptions.length}
              className="pbtn lbtn"
            >
              last
              <img src={require("../../assets/robotasset/lastp.png")} alt="" />
            </button>
            <span>
              Page{" "}
              <strong>
                {pagecount} of {pageOptions.length}
              </strong>{" "}
            </span>
          </div>
        </div>
      </div>
      <Modal
        title={MOBILE_HEADER}
        visible={mobileWarning}
        footer={null}
        closable={false}
      >
        <p>{MOBILE_CONTENT}</p>
      </Modal>
    </div>
  );
}

const mapDispatchToProps = {
  AIforcast: AIforcast,
};
const mapStateToProps = ({
  workspaceReducer: { workspaceData, workspaceList },
  userReducer: { accessToken, payment, subscription },
}) => ({
  subscription,
  workspaceData,
  accessToken,
  workspaceList,
  payment,
});
export default connect(mapStateToProps, mapDispatchToProps)(ForecastTable);
