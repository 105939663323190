import { APIService, requestURLS } from "../constants/APIConstant";
import { ERROR, SUCCESS } from "../constants/messages";
import { call, put, takeLatest } from "redux-saga/effects";

import {
  actionLoginTypes,
  actionWorkspaceTypes,
} from "../constants/actionTypes";
import { refreshToken } from "./userSaga";
import { message } from "antd";

export function* createTradingAccount(param) {
  let { accessToken, callback, data } = param;
  try {
    let recievedResponse = {};
    yield put({ type: actionWorkspaceTypes.SET_DASHBOARD_FILE_FETCHING });

    const postUrl = APIService.dev + requestURLS.CREATE_ACCOUNT;
    const headers = {
      "Content-Type": "application/json",
      Authorization: `Bearer ${accessToken}`,
    };

    const responseJSON = yield fetch(postUrl, {
      headers: headers,
      method: "POST",
      body: JSON.stringify(data),
    }).then((response) => {
      recievedResponse = response;
      return response.json();
    });
    if (!recievedResponse.ok) {
      throw responseJSON;
    }
    yield put({ type: actionWorkspaceTypes.SET_DASHBOARD_FILE_RECIEVED });
    callback(SUCCESS, responseJSON.data);
    
  } catch (e) {
    if (e.ResponseCode === 401) {
      yield put({ type: actionLoginTypes.TOKEN_EMPTY });
      yield call(refreshToken);
    }

    yield put({
      type: actionWorkspaceTypes.DASHBOARD_ERROR,
      error: e,
    });
    callback(ERROR, e.message);
  }
}
export function* updateTradingAccount(param) {
  let { accessToken, callback, data } = param;
  try {
    let recievedResponse = {};
    yield put({ type: actionWorkspaceTypes.SET_DASHBOARD_FILE_FETCHING });

    const postUrl = APIService.dev + requestURLS.UPDATE_ACCOUNT;
    const headers = {
      "Content-Type": "application/json",
      Authorization: `Bearer ${accessToken}`,
    };

    const responseJSON = yield fetch(postUrl, {
      headers: headers,
      method: "POST",
      body: JSON.stringify(data),
    }).then((response) => {
      recievedResponse = response;
      return response.json();
    });
    if (!recievedResponse.ok) {
      throw responseJSON;
    }
    yield put({ type: actionWorkspaceTypes.SET_DASHBOARD_FILE_RECIEVED });
    callback(SUCCESS, responseJSON.data);
    
  } catch (e) {
    if (e.ResponseCode === 401) {
      yield put({ type: actionLoginTypes.TOKEN_EMPTY });
      yield call(refreshToken);
    }

    yield put({
      type: actionWorkspaceTypes.DASHBOARD_ERROR,
      error: e,
    });
    callback(ERROR, e.message);
  }
}
export function* deleteACC(param) {
  let { accessToken, callback, data } = param;
  try {
    let recievedResponse = {};
    yield put({ type: actionWorkspaceTypes.SET_DASHBOARD_FILE_FETCHING });

    const postUrl = APIService.dev + requestURLS.DELETE_ACCOUNT;
    const headers = {
      "Content-Type": "application/json",
      Authorization: `Bearer ${accessToken}`,
    };

    const responseJSON = yield fetch(postUrl, {
      headers: headers,
      method: "DELETE",
      body: JSON.stringify(data),
    }).then((response) => {
      recievedResponse = response;
      return response.json();
    });
    if (!recievedResponse.ok) {
      throw responseJSON;
    }
    yield put({ type: actionWorkspaceTypes.SET_DASHBOARD_FILE_RECIEVED });
    callback(SUCCESS, responseJSON.data);
    
  } catch (e) {
    if (e.ResponseCode === 401) {
      yield put({ type: actionLoginTypes.TOKEN_EMPTY });
      yield call(refreshToken);
    }

    yield put({
      type: actionWorkspaceTypes.DASHBOARD_ERROR,
      error: e,
    });
    callback(ERROR, e.message);
  }
}

// robot dasboard


export function* getAccPass(param) {
  const {  accessToken, callback,data } = param;
  let recievedResponse = {};
  try {
    // yield put({ type: actionWorkspaceTypes.SET_DASHBOARD_FILE_FETCHING });

    const getUrl = APIService.dev + requestURLS.ACCOUNT_PASSWORD+ `?encrypt_text=`+data ;
    let headers = {
      "Content-Type": "application/json",
      Authorization: `Bearer ${accessToken}`,
    };
    const responseJSON = yield fetch(getUrl, {
      headers: headers,
      method: "GET",
    }).then((response) => {
      recievedResponse = response;
      return response.json();
    });

    if (!recievedResponse.ok) {
      throw responseJSON;
    }
    callback(SUCCESS, responseJSON.data);
    yield put({
      type: actionWorkspaceTypes.SET_DASHBOARD_FILE_RECIEVED });
  } catch (e) {
    if(e.ResponseCode===401){
      yield put({ type: actionLoginTypes.TOKEN_EMPTY });
      const newTokenData = yield call(refreshToken);
      yield put({ type: actionWorkspaceTypes.SET_DASHBOARD_FILE_FETCHING });

    const getUrl = APIService.dev + requestURLS.ACCOUNT_PASSWORD+ `?encrypt_text=`+data ;
    let headers = {
      "Content-Type": "application/json",
      Authorization: `Bearer ${newTokenData}`,
    };
    const responseJSON = yield fetch(getUrl, {
      headers: headers,
      method: "GET",
    }).then((response) => {
      recievedResponse = response;
      return response.json();
    });

    if (!recievedResponse.ok) {
      throw responseJSON;
    }
    callback(SUCCESS, responseJSON.data);
    yield put({
      type: actionWorkspaceTypes.SET_DASHBOARD_FILE_RECIEVED,
      payload: responseJSON.data,
    });
    }else{
      yield put({
        type: actionWorkspaceTypes.DASHBOARD_ERROR,
        error: e,
      });
      if (e.name === "SyntaxError") {
        message.error("Unexpected response format. Please try again later.");
      } else {
        callback(ERROR, e);
      }
      
    }
  }
}

export function* getAccList(param) {
  const {  accessToken, callback } = param;
  let recievedResponse = {};
  try {
    yield put({ type: actionWorkspaceTypes.SET_DASHBOARD_FILE_FETCHING });

    const getUrl = APIService.dev + requestURLS.ACCOUNT_LIST ;
    let headers = {
      "Content-Type": "application/json",
      Authorization: `Bearer ${accessToken}`,
    };
    const responseJSON = yield fetch(getUrl, {
      headers: headers,
      method: "GET",
    }).then((response) => {
      recievedResponse = response;
      return response.json();
    });

    if (!recievedResponse.ok) {
      throw responseJSON;
    }
    callback(SUCCESS, responseJSON.data);
    yield put({
      type: actionWorkspaceTypes.SET_DASHBOARD_FILE_RECIEVED });
  } catch (e) {
    if(e.ResponseCode===401){
      yield put({ type: actionLoginTypes.TOKEN_EMPTY });
      const newTokenData = yield call(refreshToken);
      yield put({ type: actionWorkspaceTypes.SET_DASHBOARD_FILE_FETCHING });

    const getUrl = APIService.dev + requestURLS.ACCOUNT_LIST;
    let headers = {
      "Content-Type": "application/json",
      Authorization: `Bearer ${newTokenData}`,
    };
    const responseJSON = yield fetch(getUrl, {
      headers: headers,
      method: "GET",
    }).then((response) => {
      recievedResponse = response;
      return response.json();
    });

    if (!recievedResponse.ok) {
      throw responseJSON;
    }
    callback(SUCCESS, responseJSON.data);
    yield put({
      type: actionWorkspaceTypes.SET_DASHBOARD_FILE_RECIEVED,
      payload: responseJSON.data,
    });
    }else{
      yield put({
        type: actionWorkspaceTypes.DASHBOARD_ERROR,
        error: e,
      });
      if (e.name === "SyntaxError") {
        message.error("Unexpected response format. Please try again later.");
      } else {
        callback(ERROR, e);
      }
      
    }
  }
}
export function* getRobotRunList(param) {
  const {  accessToken, callback ,data} = param;
  let recievedResponse = {};
  try {
    yield put({ type: actionWorkspaceTypes.SET_DASHBOARD_FILE_FETCHING });


    const searchParams = new URLSearchParams(data);
    const apiUrl = `?${searchParams.toString()}`;


    const getUrl = APIService.dev + requestURLS.ROBOTS_RUN_LIST +apiUrl;


    let headers = {
      "Content-Type": "application/json",
      Authorization: `Bearer ${accessToken}`,
    };
    const responseJSON = yield fetch(getUrl, {
      headers: headers,
      method: "GET",
    }).then((response) => {
      recievedResponse = response;
      return response.json();
    });

    if (!recievedResponse.ok) {
      throw responseJSON;
    }
    callback(SUCCESS, responseJSON.data);
    yield put({
      type: actionWorkspaceTypes.SET_DASHBOARD_FILE_RECIEVED });
  } catch (e) {
    if(e.ResponseCode===401){
      yield put({ type: actionLoginTypes.TOKEN_EMPTY });
      const newTokenData = yield call(refreshToken);
      yield put({ type: actionWorkspaceTypes.SET_DASHBOARD_FILE_FETCHING });

    const getUrl = APIService.dev + requestURLS.ROBOTS_RUN_LIST;
    let headers = {
      "Content-Type": "application/json",
      Authorization: `Bearer ${newTokenData}`,
    };
    const responseJSON = yield fetch(getUrl, {
      headers: headers,
      method: "GET",
    }).then((response) => {
      recievedResponse = response;
      return response.json();
    });

    if (!recievedResponse.ok) {
      throw responseJSON;
    }
    callback(SUCCESS, responseJSON.data);
    yield put({
      type: actionWorkspaceTypes.SET_DASHBOARD_FILE_RECIEVED,
      payload: responseJSON.data,
    });
    }else{
      yield put({
        type: actionWorkspaceTypes.DASHBOARD_ERROR,
        error: e,
      });
      if (e.name === "SyntaxError") {
        message.error("Unexpected response format. Please try again later.");
      } else {
        callback(ERROR, e);
      }
      
    }
  }
}

export function* getRobotOptionList(param) {
  const {  accessToken, callback } = param;
  let recievedResponse = {};
  try {
    yield put({ type: actionWorkspaceTypes.SET_DASHBOARD_FILE_FETCHING });

    const getUrl = APIService.dev + requestURLS.GET_ALL_ROBOTS_OPTIONS;
    let headers = {
      "Content-Type": "application/json",
      Authorization: `Bearer ${accessToken}`,
    };
    const responseJSON = yield fetch(getUrl, {
      headers: headers,
      method: "GET",
    }).then((response) => {
      recievedResponse = response;
      return response.json();
    });

    if (!recievedResponse.ok) {
      throw responseJSON;
    }
    callback(SUCCESS, responseJSON.data);
    yield put({
      type: actionWorkspaceTypes.SET_DASHBOARD_FILE_RECIEVED });
  } catch (e) {
   
    if (e.ResponseCode === 401) {
      yield put({ type: actionLoginTypes.TOKEN_EMPTY });
      yield call(refreshToken);
    }

    yield put({
      type: actionWorkspaceTypes.DASHBOARD_ERROR,
      error: e,
    });
    callback(ERROR, e.message);
  }
}

export function* getRobotList(param) {

  let { accessToken, callback, data } = param;

  try {
    let recievedResponse = {};
    yield put({ type: actionWorkspaceTypes.SET_DASHBOARD_FILE_FETCHING });

    const postUrl = APIService.dev + requestURLS.ROBOTS_START_NOW_API;
    const headers = {
      "Content-Type": "application/json",
      Authorization: `Bearer ${accessToken}`,
    };

    const responseJSON = yield fetch(postUrl, {
      headers: headers,
      method: "POST",
      body: JSON.stringify(data),
    }).then((response) => {
      recievedResponse = response;
      return response.json();
    });
    if (!recievedResponse.ok) {
      throw responseJSON;
    }
    yield put({ type: actionWorkspaceTypes.SET_DASHBOARD_FILE_RECIEVED });
    callback(SUCCESS, responseJSON.data);
    
  } catch (e) {
    if (e.ResponseCode === 401) {
      yield put({ type: actionLoginTypes.TOKEN_EMPTY });
      yield call(refreshToken);
    }

    yield put({
      type: actionWorkspaceTypes.DASHBOARD_ERROR,
      error: e,
    });
    callback(ERROR, e.message);
  }
}
export function* postRobotStatus(param) {

  let { accessToken, callback, data } = param;

  try {
    let recievedResponse = {};
    yield put({ type: actionWorkspaceTypes.SET_DASHBOARD_FILE_FETCHING });

    const postUrl = APIService.dev + requestURLS.ROBOTS_STOP_NOW_API;
    const headers = {
      "Content-Type": "application/json",
      Authorization: `Bearer ${accessToken}`,
    };

    const responseJSON = yield fetch(postUrl, {
      headers: headers,
      method: "POST",
      body: JSON.stringify(data),
    }).then((response) => {
      recievedResponse = response;
      return response.json();
    });
    if (!recievedResponse.ok) {
      throw responseJSON;
    }
    yield put({ type: actionWorkspaceTypes.SET_DASHBOARD_FILE_RECIEVED });
    callback(SUCCESS, responseJSON.data);
    
  } catch (e) {
    if (e.ResponseCode === 401) {
      yield put({ type: actionLoginTypes.TOKEN_EMPTY });
      yield call(refreshToken);
    }

    yield put({
      type: actionWorkspaceTypes.DASHBOARD_ERROR,
      error: e,
    });
    callback(ERROR, e.message);
  }
}
export function* postRunBacktest(param) {

  let { accessToken, callback, data } = param;

  try {
    let recievedResponse = {};
    yield put({ type: actionWorkspaceTypes.SET_DASHBOARD_FILE_FETCHING });

    const postUrl = APIService.dev + requestURLS.ROBOTS_RUN_BACKTEST;
    const headers = {
      "Content-Type": "application/json",
      Authorization: `Bearer ${accessToken}`,
    };

    const responseJSON = yield fetch(postUrl, {
      headers: headers,
      method: "POST",
      body: JSON.stringify(data),
    }).then((response) => {
      recievedResponse = response;
      return response.json();
    });
    if (!recievedResponse.ok) {
      throw responseJSON;
    }
    yield put({ type: actionWorkspaceTypes.SET_DASHBOARD_FILE_RECIEVED });
    callback(SUCCESS, responseJSON.data);
    
  } catch (e) {
    if (e.ResponseCode === 401) {
      yield put({ type: actionLoginTypes.TOKEN_EMPTY });
      yield call(refreshToken);
    }

    yield put({
      type: actionWorkspaceTypes.DASHBOARD_ERROR,
      error: e,
    });
    callback(ERROR, e.message);
  }
}
export function* postUpdateBacktest(param) {

  let { accessToken, callback, data } = param;

  try {
    let recievedResponse = {};
    yield put({ type: actionWorkspaceTypes.SET_DASHBOARD_FILE_FETCHING });

    const postUrl = APIService.dev + requestURLS.STOP_BACKTEST +data?.row_id +'/update/';
    const headers = {
      "Content-Type": "application/json",
      Authorization: `Bearer ${accessToken}`,
    };


    const UpdateData=data
    delete data.row_id
    const responseJSON = yield fetch(postUrl, {
      headers: headers,
      method: "PUT",
      body: JSON.stringify(UpdateData),
    }).then((response) => {
      recievedResponse = response;
      return response.json();
    });
    if (!recievedResponse.ok) {
      throw responseJSON;
    }
    yield put({ type: actionWorkspaceTypes.SET_DASHBOARD_FILE_RECIEVED });
    callback(SUCCESS, responseJSON.data);
    
  } catch (e) {
    if (e.ResponseCode === 401) {
      yield put({ type: actionLoginTypes.TOKEN_EMPTY });
      yield call(refreshToken);
    }

    yield put({
      type: actionWorkspaceTypes.DASHBOARD_ERROR,
      error: e,
    });
    callback(ERROR, e.message);
  }
}
export function* postStopBacktest(param) {

  let { accessToken, callback, data } = param;

  try {
    let recievedResponse = {};
    yield put({ type: actionWorkspaceTypes.SET_DASHBOARD_FILE_FETCHING });

    const postUrl = APIService.dev + requestURLS.STOP_BACKTEST +data +'/stop/';
    const headers = {
      "Content-Type": "application/json",
      Authorization: `Bearer ${accessToken}`,
    };

    const responseJSON = yield fetch(postUrl, {
      headers: headers,
      method: "POST",
      body: JSON.stringify(data),
    }).then((response) => {
      recievedResponse = response;
      return response.json();
    });
    if (!recievedResponse.ok) {
      throw responseJSON;
    }
    yield put({ type: actionWorkspaceTypes.SET_DASHBOARD_FILE_RECIEVED });
    callback(SUCCESS, responseJSON.data);
    
  } catch (e) {
    if (e.ResponseCode === 401) {
      yield put({ type: actionLoginTypes.TOKEN_EMPTY });
      yield call(refreshToken);
    }

    yield put({
      type: actionWorkspaceTypes.DASHBOARD_ERROR,
      error: e,
    });
    callback(ERROR, e.message);
  }
}
export function* postDeleteBacktest(param) {

  let { accessToken, callback, data } = param;

  try {
    let recievedResponse = {};
    yield put({ type: actionWorkspaceTypes.SET_DASHBOARD_FILE_FETCHING });

    const postUrl = APIService.dev + requestURLS.STOP_BACKTEST +data +'/delete/';
    const headers = {
      "Content-Type": "application/json",
      Authorization: `Bearer ${accessToken}`,
    };

    const responseJSON = yield fetch(postUrl, {
      headers: headers,
      method: "GET",
    }).then((response) => {
      recievedResponse = response;
      return response.json();
    });
    if (!recievedResponse.ok) {
      throw responseJSON;
    }
    yield put({ type: actionWorkspaceTypes.SET_DASHBOARD_FILE_RECIEVED });
    callback(SUCCESS, responseJSON.data);
    
  } catch (e) {
    if (e.ResponseCode === 401) {
      yield put({ type: actionLoginTypes.TOKEN_EMPTY });
      yield call(refreshToken);
    }

    yield put({
      type: actionWorkspaceTypes.DASHBOARD_ERROR,
      error: e,
    });
    callback(ERROR, e.message);
  }
}

export function* getBacktestList(param) {
  const {  accessToken, callback,data } = param;
  let recievedResponse = {};
  try {
    yield put({ type: actionWorkspaceTypes.SET_DASHBOARD_FILE_FETCHING });

    const searchParams = new URLSearchParams(data);
    const apiUrl = `?${searchParams.toString()}`;

    const getUrl = APIService.dev + requestURLS.GET_BACKTEST +apiUrl;
    let headers = {
      "Content-Type": "application/json",
      Authorization: `Bearer ${accessToken}`,
    };
    const responseJSON = yield fetch(getUrl, {
      headers: headers,
      method: "GET",
    }).then((response) => {
      recievedResponse = response;
      return response.json();
    });

    if (!recievedResponse.ok) {
      throw responseJSON;
    }
    callback(SUCCESS, responseJSON.data);
    yield put({
      type: actionWorkspaceTypes.SET_DASHBOARD_FILE_RECIEVED });
  } catch (e) {

    if (e.ResponseCode === 401) {
      yield put({ type: actionLoginTypes.TOKEN_EMPTY });
      yield call(refreshToken);
    }

    yield put({
      type: actionWorkspaceTypes.DASHBOARD_ERROR,
      error: e,
    });
    callback(ERROR, e.message);
  }
}


export function* dashboardActionWatcher() {
  yield takeLatest(actionWorkspaceTypes.ROBOT_LIST_START_NOW, getRobotList);
  yield takeLatest(actionWorkspaceTypes.ROBOT_LIST_STOP_NOW, postRobotStatus);
  yield takeLatest(actionWorkspaceTypes.ROBOT_RUN_LIST, getRobotRunList);
  yield takeLatest(actionWorkspaceTypes.ROBOT_OPTIONS_DROPDOWN, getRobotOptionList);
  yield takeLatest(actionWorkspaceTypes.POST_DASHBOARD, createTradingAccount);
  yield takeLatest(actionWorkspaceTypes.UPDATE_ACCOUNT_DATA, updateTradingAccount);
  yield takeLatest(actionWorkspaceTypes.ACCOUNT_PASS, getAccPass);
  yield takeLatest(actionWorkspaceTypes.ACCOUNT_LIST, getAccList);
  yield takeLatest(actionWorkspaceTypes.ACCOUNT_DELETE, deleteACC);
  yield takeLatest(actionWorkspaceTypes.RUN_BACKTEST, postRunBacktest);
  yield takeLatest(actionWorkspaceTypes.STOP_BACKTEST, postStopBacktest);
  yield takeLatest(actionWorkspaceTypes.UPDATE_BACKTEST, postUpdateBacktest);
  yield takeLatest(actionWorkspaceTypes.LIST_BACKTEST, getBacktestList);
  yield takeLatest(actionWorkspaceTypes.DELETE_BACKTEST, postDeleteBacktest);
}
