// ForecastTable.js
import React, { useEffect, useMemo, useState } from "react";
import { useTable, usePagination, useSortBy } from "react-table";
import { Select, Input, Button, message, Modal, Popconfirm } from "antd";
import { getAccList,getRobotList,getRobotOptionList, getRobotRunList, stopRobot } from "../../actions/dashboardActions";
import {
  ERROR,
  MOBILE_CONTENT,
  MOBILE_HEADER,
  SUCCESS,
} from "../../constants/messages";
import { connect } from "react-redux";
import Swap from "../../assets/robotasset/Swap.png";
import Ace from "../../assets/robotasset/asc.png";
import Dec from "../../assets/robotasset/dec.png";

function RobotDashboard(props) {
  const [records, setrecords] = useState([]);

  const [symbol, setSymbol] = useState("");
  const [accNo, setaccNo] = useState("");
  const [AccOpt, setAccOpt] = useState([]);
  const [RobotOpt, setRobotOpt] = useState([]);
  const [searchString, setSearchString] = useState("");
  const [timeframe, setTimeframe] = useState("");

  const [pagecount, setpagecount] = useState(1);
  const [totalrecord, settotalrecord] = useState(0);
  const [searchbtn, setsearchbtn] = useState(true);
  
  const [refresh, setrefresh] = useState(false);
  const [mobileWarning, setMobileWarning] = useState(false);

  const [sortBy, setSortBy] = useState("");
  const [sortOrder, setSortOrder] = useState({
    period: "asc",
    symbol: "asc",
    robot_name: "asc",
  });


  const handleSort = (columnId) => {
    setSortBy(columnId);
    setSortOrder((prevOrder) => {
      const newOrder = prevOrder[columnId] === "asc" ? "desc" : "asc";
      return { ...prevOrder, [columnId]: newOrder };
    });
   
  };
  const data = useMemo(() => records, [records]);



  const handleChangeselect = (value) => {
    setSymbol(value);
  };

  const searchText = (value) => {
    setSearchString(value);
  };
  const searchTextAcc = (value) => {
    setaccNo(value);
  };
  const handleChangetimeframe = (value) => {
    setTimeframe(value);
  };

  const confirm = (e,status) => {
    handleToggleStatus(e,status);
  };
  const cancel = (e) => {};

  const getListsData = () => {
    props.getAccList({
      accessToken: localStorage.getItem("token"),
      callback: (status, info) => {
        if (status === ERROR) {
          message.error(info || "error");
        }
        if (status === SUCCESS) {

          if (info) {
            let x = [];
            info.map((e) => {
              x.push({
                value: e?.trading_account_number,
                label: e?.trading_account_number,
              });
            });
            setAccOpt(x);
          }

        }
      },
    });
    props.getRobotOptionList({
      accessToken: localStorage.getItem("token"),
      callback: (status, info) => {
        if (status === ERROR) {
          message.error(info || "error"); 
        }
        if (status === SUCCESS) {

          if (info) {
            let x = [];
            info.map((e) => {
              x.push({
                value: e?.robot_id,
                label: e?.robot_name,
              });
            });
            setRobotOpt(x);
          }

        }
      },
    });
  };
  useEffect(() => {
    getListsData();
  }, []);

  const handleFilter = (key) => {

    if (symbol !== "" || searchString) {
      const params = {};
      if (symbol) params.symbol = symbol;
      if (searchString) params.robot_id = searchString;
      if (accNo) params.account_number = accNo;
      if (pagecount) params.page = pagecount;
      if (timeframe) params.period = timeframe;
   
      const accessToken = localStorage.getItem("token");


      props.getRobotList({
        data: params,
        accessToken,
        callback: (status, info) => {
          console.log(status,info);
          
          if (status === ERROR) {
          }
          if (status === SUCCESS) {
            message.success('Robot started successfully')
            setrefresh(!refresh)
          }
        },
      });

    } else {
      setsearchbtn(true);
    }
  };

  useEffect(() => {
    function updateSize() {
      setMobileWarning(window.innerWidth < 700);
    }
    window.addEventListener("resize", updateSize);
    updateSize();
    return () => {
      window.removeEventListener("resize", updateSize);
    };
  }, []);
  const getRunRobotList = () => { 
    const params = {};
      if (pagecount) params.page = pagecount;
      if (sortBy) params.sort_by = sortBy;
      if (sortOrder[sortBy]) params.sort_order = sortOrder[sortBy];

    props.getRobotRunList({
      accessToken: localStorage.getItem("token"),
      data:params,
      callback: (status, info) => {

        if (status === ERROR) {
          // message.error(info || "error");
        }
        if (status === SUCCESS) {
          if (info) {
            setrecords(info?.results);
            settotalrecord(info?.count)
          }

        }
      },
    });
  }

  useEffect(() => {
    getRunRobotList();
  }, [sortBy,sortOrder,pagecount, refresh]);

  useEffect(() => {
    if (
      accNo !== "" &&
      symbol !== "" &&
      searchString !== ""  &&
      timeframe !== ""
    ) {
      setsearchbtn(false);
    }
  }, [accNo, symbol, searchString, timeframe]);

  const updateStatusAPI = async (rowId) => {
    const params = {};
    if (rowId) params.hypa_account_robot_id = rowId;
    const accessToken = localStorage.getItem("token");
    props.stopRobot({
      data: params,
      accessToken,
      callback: (status, info) => {
        if (status === ERROR) {
        }
        if (status === SUCCESS) {
          setrefresh(!refresh)
        }
      },
    });
  
  };

  const handleToggleStatus = (rowId,status) => {
    const row = records.find((record) => record.id === rowId);
  

    if (!row) return;
    if(status==='Stop'){
      updateStatusAPI(row.id);
    }
    if(status==='Run'){   
      const params = {};
       params.symbol = row?.symbol;
       params.robot_id = row?.robot;
       params.account_number = row?.trading_account_number;  
       params.period = row?.period;
      const accessToken = localStorage.getItem("token");
 
      props.getRobotList({
        data: params,
        accessToken,
        callback: (status, info) => {
          
          if (status === ERROR) {
          }
          if (status === SUCCESS) {
            setrefresh(!refresh)
            message.success('Robot started successfully')
          }
        },
      });
      
    }
  };

  const columns = useMemo(
    () => [
      { Header: "Robot Name", accessor: "robot_name" },
      { Header: "Account Number", accessor: "trading_account_number" },
      {  Header: () => (
        <div onClick={() => handleSort("symbol",sortBy)}>
          Instruments 
          <img
            style={{ height: "15px" }}
            src={sortBy===""?Swap:sortBy==='symbol'? sortOrder.symbol==='asc'? Ace :Dec:Swap}
            alt="img"
          />
        </div>
      ), accessor: "symbol" },
      {  Header: () => (
        <div onClick={() => handleSort("period",sortBy)}>
          Timeframe 
          <img
            style={{ height: "15px" }}
            src={sortBy===""?Swap:sortBy==='period'? sortOrder.period==='asc'? Ace :Dec:Swap}
            alt="img"
          />
        </div>
      ), accessor: "period" },
      {
        Header: "Action",
        accessor: "robot_status",
        Cell: ({ row }) => (
          <Popconfirm
            title={`Do you want to ${
              row.original.robot_status === "RUNNING" ? "Stop" : "Run"
            }?`}
            onConfirm={() => confirm(row.original.id,row.original.robot_status === "RUNNING" ? "Stop" : "Run")}
            onCancel={cancel}
            okText="Yes"
            cancelText="No"
          >
            <button
              style={{
                padding: "5px 10px",
                backgroundColor:
                row.original.robot_status === "RUNNING" ? "red" : "green",
                color: "white",
                border: "none",
                borderRadius: "5px",
                cursor: "pointer",
              }}
            >
              {row.original.robot_status === "RUNNING" ? "Stop" : "Run"}
            </button>
          </Popconfirm>
        ),
      },
      {
        Header: "Status",
        accessor: "status_display",
        Cell: ({ row }) => (
          <span
            style={{
              color:
              row.original.robot_status === "RUNNING"  ? "green" : "red",
              fontWeight: "bold",
            }}
          >
            {row.original.robot_status === "RUNNING"  ? "Running" : "Stopped"}
          </span>
        ),
      },
    ],
    [records,sortOrder,sortBy]
  );

  const {
    getTableProps,
    getTableBodyProps,
    headerGroups,
    prepareRow,
    page,
    canPreviousPage,
    canNextPage,
    pageOptions,
    pageCount,
    gotoPage,
    nextPage,
    previousPage,
    state: { pageIndex },
  } = useTable(
    {
      columns,
      data,
      manualPagination: true,
      pageCount: Math.ceil(totalrecord / 10),
    },
    useSortBy,
    usePagination
  );
console.log(records);

  return (
    <div className="table_pannel">
      <div className="" style={{ padding: "30px", background: "#fff" }}>
        <div className="search_pannel">
          <div className="filter_block">
            <p className="filter-label">symbol name</p>
            <Select
              showSearch
              optionFilterProp="children"
              filterOption={(input, option) =>
                (option?.label ?? "").includes(input.toUpperCase())
              }
              filterSort={(optionA, optionB) =>
                (optionA?.label ?? "")
                  .toUpperCase()
                  .localeCompare((optionB?.label ?? "").toUpperCase())
              }
              value={symbol === "" ? null : symbol}
              placeholder="Select symbol"
              className="sym_select"
              onChange={handleChangeselect}
              options={[
                { value: "CADJPY", label: "CADJPY" },
                { value: "USDJPY", label: "USDJPY" },
                { value: "AUDUSD", label: "AUDUSD" },
                { value: "EURCAD", label: "EURCAD" },
                { value: "EURCHF", label: "EURCHF" },
                { value: "AUDNZD", label: "AUDNZD" },
                { value: "EURAUD", label: "EURAUD" },
                { value: "EURJPY", label: "EURJPY" },
                { value: "GER40", label: "GER40" },
                { value: "US500", label: "US500" },
                { value: "GBPNZD", label: "GBPNZD" },
                { value: "EURUSD", label: "EURUSD" },
                { value: "NZDUSD", label: "NZDUSD" },
                { value: "USDCAD", label: "USDCAD" },
                { value: "AUDCAD", label: "AUDCAD" },
                { value: "UK100", label: "UK100" },
                { value: "XAGUSD", label: "XAGUSD" },
                { value: "EURNZD", label: "EURNZD" },
                { value: "GBPCHF", label: "GBPCHF" },
                { value: "XTIUSD", label: "XTIUSD" },
                { value: "CHFJPY", label: "CHFJPY" },
                { value: "NZDCAD", label: "NZDCAD" },
                { value: "GBPAUD", label: "GBPAUD" },
                { value: "BTCUSD", label: "BTCUSD" },
                { value: "NZDCHF", label: "NZDCHF" },
                { value: "US30", label: "US30" },
                { value: "NZDJPY", label: "NZDJPY" },
                { value: "CADCHF", label: "CADCHF" },
                { value: "XAUUSD", label: "XAUUSD" },
                { value: "USDCHF", label: "USDCHF" },
                { value: "EURGBP", label: "EURGBP" },
                { value: "GBPCAD", label: "GBPCAD" },
                { value: "GBPJPY", label: "GBPJPY" },
                { value: "AUDJPY", label: "AUDJPY" },
                { value: "GBPUSD", label: "GBPUSD" },
                { value: "ETHUSD", label: "ETHUSD" },
              ]}
            />
          </div>

          <div className="filter_block">
            <p className="filter-label">Search by</p>
            <Select
              showSearch
              optionFilterProp="children"
              filterOption={(input, option) =>
                (option?.label ?? "").includes(input.toUpperCase())
              }
              filterSort={(optionA, optionB) =>
                (optionA?.label ?? "")
                  .toUpperCase()
                  .localeCompare((optionB?.label ?? "").toUpperCase())
              }
              value={searchString === "" ? null : searchString}
              placeholder="Search robot"
              className="sym_select"
              onChange={searchText}
              options={RobotOpt}
            />
          </div>
          <div className="filter_block">
            <p className="filter-label">Account Number</p>
            <Select
              showSearch
              optionFilterProp="children"
              value={accNo === "" ? null : accNo}
              placeholder="Search account number"
              className="sym_select"
              onChange={searchTextAcc}
              options={AccOpt}
            />
          </div>
          <div className="filter_block">
            <p className="filter-label">Timeframe</p>
            <Select
              value={timeframe === "" ? null : timeframe}
              className="sym_select"
              placeholder="Select timeframe"
              onChange={handleChangetimeframe}
              options={[
                { value: "M30", label: "M30" },
                { value: "M15", label: "M15" },
                { value: "H1", label: "H1" },
                { value: "H4", label: "H4" },
                { value: "D1", label: "D1" },
              ]}
            />
          </div>
          <div className="filter_block btn-grp">
            <Button
              disabled={searchbtn}
              type="primary btns srch_btn"
              onClick={() => handleFilter("search")}
            >
              Start Now
            </Button>
          </div>
        </div>
        <div className="filter_pannel">
          <table
            {...getTableProps()}
            style={{
              width: "100%",
              borderCollapse: "collapse",
              border: "solid 1px #DBDCDE",
            }}
          >
            <thead>
              {headerGroups.map((headerGroup) => (
                <tr {...headerGroup.getHeaderGroupProps()}>
                  {headerGroup.headers.map((column) => (
                    <th
                      {...column.getHeaderProps(column.getSortByToggleProps())}
                      style={{
                        padding: "15px",
                        background: "var(--primary-color)",
                        color: "#fff",
                        fontWeight: "bold",
                        cursor: "pointer",
                        textTransform: "uppercase",
                        textAlign: "center",
                        fontSize: "15px",
                      }}
                    >
                      {column.render("Header")}
                    </th>
                  ))}
                </tr>
              ))}
            </thead>
            <tbody {...getTableBodyProps()}>
              {page.map((row) => {
                prepareRow(row);
                return (
                  <tr {...row.getRowProps()}>
                    {row.cells.map((cell) => (
                      <td
                        {...cell.getCellProps()}
                        style={{
                          padding: "10px 15px",
                          borderBottom: "solid 1px var(--bordercolor)",
                          background: "#fff",
                          textAlign: "center",
                          fontWeight: "700",
                          fontSize: "14px",
                        }}
                      >
                        {cell.render("Cell")}
                      </td>
                    ))}
                  </tr>
                );
              })}
            </tbody>
          </table>
          <div className="pagination">
            <button
              className="pbtn fbtn"
              onClick={() => setpagecount(1)}
              disabled={pagecount <= 0}
            >
              <img
                src={require("../../assets/robotasset/firstarroe.png")}
                alt=""
              />
              first
            </button>{" "}
            <button
              className="pbtn fbtn"
              onClick={() => setpagecount(pagecount > 1 ? pagecount - 1 : 1)}
              disabled={pagecount <= 0}
            >
              <img
                src={require("../../assets/robotasset/backarrow.png")}
                alt=""
              />
              back
            </button>{" "}
            {/* {renderPageNumbers()} */}
            <button
              className="pbtn lbtn"
              onClick={() => setpagecount(pagecount + 1)}
              disabled={pagecount === pageOptions.length}
            >
              next
              <img src={require("../../assets/robotasset/prarr.png")} alt="" />
            </button>{" "}
            <button
              onClick={() => setpagecount(Math.ceil(totalrecord / 10))}
              disabled={pagecount === pageOptions.length}
              className="pbtn lbtn"
            >
              last
              <img src={require("../../assets/robotasset/lastp.png")} alt="" />
            </button>
            <span>
              Page{" "}
              <strong>
                {pagecount} of {pageOptions.length}
              </strong>{" "}
            </span>
          </div>
        </div>
      </div>
      <Modal
        title={MOBILE_HEADER}
        visible={mobileWarning}
        footer={null}
        closable={false}
      >
        <p>{MOBILE_CONTENT}</p>
      </Modal>
    </div>
  );
}

const mapDispatchToProps = {
  getAccList: getAccList,
  getRobotOptionList:getRobotOptionList,
  getRobotList:getRobotList,
  getRobotRunList:getRobotRunList,
  stopRobot:stopRobot
  
};
const mapStateToProps = ({ dashboardReducer: { fetchingDashboard } }) => ({
  fetchingDashboard,
});
export default connect(mapStateToProps, mapDispatchToProps)(RobotDashboard);
